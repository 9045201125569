import './MenuItemsBlock.css'
import React from "react";
import {RoutePaths} from "../../../constants/RoutePaths";
import {Lang} from "../../../constants/Lang";
import {ExternalLinks} from "../../../constants/ExternalLinks";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../redux/ReduxStore";
import {useLocation} from "react-router-dom";
import MenuItemWithLink from "../menu_item/MenuItemWithLink";

import emailCursor from '../../../assets/cursor_icons/email.svg'
import behanceCursor from '../../../assets/cursor_icons/behance.svg'
import tgCursor from '../../../assets/cursor_icons/tg.svg'

interface MenuItemsBlockProps {
  containerStyle?: React.CSSProperties;
  onlyRoutes?: boolean;
}

const MenuItemsBlock: React.FC<MenuItemsBlockProps> = ({ containerStyle, onlyRoutes}) => {
  const currLang = useSelector((state: RootStoreState) => state.lang)
  const location = useLocation()

  return(
    <div className={'mobile-menu-items-block'} style={containerStyle || {}}>
      <MenuItemWithLink
        to={RoutePaths.HOME}
        text={currLang === Lang.ENG ? 'Projects' : 'Проекты'}
        isActive={location?.pathname === RoutePaths.HOME}
      />
      <MenuItemWithLink
        to={RoutePaths.ARCHIVE}
        text={currLang === Lang.ENG ? 'Archive' : 'Архив'}
        isActive={location?.pathname === RoutePaths.ARCHIVE}
      />
      <MenuItemWithLink
        to={RoutePaths.ABOUT}
        text={currLang === Lang.ENG ? 'About' : 'Обо мне'}
        isActive={location?.pathname === RoutePaths.ABOUT}
      />
      <MenuItemWithLink
        to={RoutePaths.MATERIALS}
        text={currLang === Lang.ENG ? 'Materials' : 'Материалы'}
        isActive={location?.pathname === RoutePaths.MATERIALS}
      />
      {!onlyRoutes && (
        <>
          <MenuItemWithLink
            to={ExternalLinks.BEHANCE}
            external={true}
            text={currLang === Lang.ENG ? 'Behance' : 'Биханс'}
            isActive={false}
            cursor={behanceCursor}
          />
          <MenuItemWithLink
            to={ExternalLinks.TELEGRAM}
            external={true}
            text={currLang === Lang.ENG ? 'Telegram' : 'Телеграм'}
            isActive={false}
            cursor={tgCursor}
          />
          <MenuItemWithLink
            to={`mailto:${ExternalLinks.EMAIL}`}
            external={true}
            text={currLang === Lang.ENG ? 'Email' : 'Эл. почта'}
            isActive={false}
            cursor={emailCursor}
          />
        </>
      )}
    </div>
  )
}

export default MenuItemsBlock