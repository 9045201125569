import './ProjectCardArchive.css'
import React from "react";
import {BaseProjectInfo} from "../../../../types/ProjectInfoTypes";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/MediaQueries";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../../redux/ReduxStore";
import {Lang} from "../../../../constants/Lang";
import {ColorTheme} from "../../../../constants/ColorTheme";
import './../../../../assets/styles/animation_durations.css'
import {Link} from "react-router-dom";
import useHover from "../../../../utils/hooks/UseHoverHook";
import {RoutePaths} from "../../../../constants/RoutePaths";
import TextFormatterComponent from "../../../../utils/formatters/TextFormatterComponent";

const ProjectCardArchive: React.FC<BaseProjectInfo & { isImplemented: boolean }> = ({
  descriptionArchiveENG,
  descriptionArchiveRUS,
  id,
  engName,
  rusName,
  works,
  platforms,
  isImplemented,
  externalLink,
  useExternalLink
}) => {
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)
  const currLang = useSelector((state: RootStoreState) => state.lang)
  const {isHovered, ...hoverHandlers} = useHover()
  const Wrapper = isImplemented || (externalLink && useExternalLink)? Link : "div";

  return(
    <Wrapper
      to={!useExternalLink || !externalLink
        ? RoutePaths.PROJECT_DETAILED.replace(':id', id)
        : externalLink
      }
      target={useExternalLink ? '_blank' : undefined}
      style={{ display: 'flex', textDecoration: "none" }}
      {...hoverHandlers}
    >
      <div
        className={`project-card-archive ${!isMobile && 'desktop'}`}
        style={{ cursor: isHovered && (isImplemented || (useExternalLink && externalLink)) ? 'pointer' : undefined }}
      >
        <div className={'header-and-link-wrapper'}>
          <h3 className={`animation-02s-all ${isMobile ? 'mobile-h3-text' : 'desktop-h3-text'} ${currTheme === ColorTheme.DARK ?'text-primary-text-dark' : 'text-primary-text'}`}>
            {currLang === Lang.RUS && rusName ? rusName : engName}
          </h3>
          {(isImplemented || (useExternalLink && externalLink)) && (
            <svg
              className={'animation-02s-all'}
              style={{ marginLeft: isHovered ? 4 : 0 }}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={'animation-02s-all'}
                d="M6 12L17 12M17 12L12 17M17 12L12 7"
                stroke={isHovered
                  ? currTheme === ColorTheme.DARK ? 'white' : 'black'
                  : currTheme === ColorTheme.DARK ? 'rgba(172, 172, 172, 1)' : 'rgba(157, 161, 174, 1)'
                }
                strokeWidth="2"
                strokeLinecap="square"
              />
            </svg>
          )}
        </div>
        <div className={'archive-text-wrapper'}>
          <TextFormatterComponent
            text={currLang === Lang.ENG ? descriptionArchiveENG : descriptionArchiveRUS}
            classNames={`animation-02s-all ${isMobile ? 'mobile-main-text' : 'desktop-main-text'} ${currTheme === ColorTheme.DARK ?'text-primary-text-dark' : 'text-primary-text'}`}
          />
          <div className={`animation-02s-all works-wrapper ${isMobile ? 'mobile-main-text' : 'desktop-main-text'} ${currTheme === ColorTheme.DARK ?'text-gray-main-text-dark' : 'text-gray-main-text'}`}>
            <div>
              {platforms?.join(', ')}
            </div>
            <div>
              {works?.join(', ')}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default ProjectCardArchive
