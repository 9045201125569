import React from "react";
import Credits from "../../ui/pages/project_detailed_page/info_components/credits/Credits";
import MediaComponentWrapper, {
  MediaComponentStructure,
} from "../../ui/pages/project_detailed_page/info_components/media_component/MediaComponentWrapper";
import ImageComponent
  from "../../ui/pages/project_detailed_page/info_components/media_component/image_component/ImageComponent";

const CentrofinansPage: React.FC = () => {
  return(
    <>
      <MediaComponentWrapper
        structure={MediaComponentStructure.WITH_TEXT}
        engText={'Read more about the project on Behance.'}
        rusText={'Подробнее о кейсе – по ссылке на Биханс.'}
      >
        <ImageComponent src={'https://i.imgur.com/BR4m0cC.jpg'} />
      </MediaComponentWrapper>
      <Credits lines={[
        { rusName: 'Product and UI/UX design:', rusVal: 'Глеб Коссов', engName: 'Product and UI/UX design:', engVal: 'Gleb Kossov' },
        { engName: 'Licensed mockups:', engVal: 'LS graphics', link: 'https://www.ls.graphics/' }
      ]}/>
    </>
  )
}

export default CentrofinansPage