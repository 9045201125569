import './NotFoundPage.css'
import React from "react";
import {MediaQueries} from "../../../constants/MediaQueries";
import {useMediaQuery} from "react-responsive";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../redux/ReduxStore";
import {ColorTheme} from "../../../constants/ColorTheme";
import {Lang} from "../../../constants/Lang";
import {RoutePaths} from "../../../constants/RoutePaths";
import StandardButtonWithLink from "../../shared_components/standard_button/StandardButtonWithLink";

const NotFoundPage: React.FC = ({  }) => {
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })
  const isDesktop = useMediaQuery({ query: MediaQueries.DESKTOP })
  const currLang = useSelector((state: RootStoreState) => state.lang)
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)

  return(
    <div className={`not-found-page-wrapper ${!isMobile && 'desktop'}`}>
      <div className={`animation-02s-all ${isDesktop ? 'text-404-desktop' : 'text-404-mobile'} ${currTheme === ColorTheme.DARK ? 'text-404-dark' : 'text-404-light'}`}>
        404
      </div>
      <div className={'not-found-text-wrapper'}>
        <div className={`animation-02s-all ${isMobile ? 'mobile-h1-text' : 'desktop-h1-text'} ${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'}`}>
          {currLang === Lang.ENG ? 'There\'s no such page' : 'Такой страницы нет'}
        </div>
        <div className={`animation-02s-all ${isMobile ? 'mobile-main-text' : 'desktop-main-text'} ${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'}`}>
          {currLang === Lang.ENG ? 'But there\'s a lot of another interesting stuff:' : 'Но есть еще много интересного:'}
        </div>
        <div style={{ marginTop: 24 }}>
          <StandardButtonWithLink
            text={currLang === Lang.RUS ? 'Вернуться на главную' : 'Return to home page'}
            to={RoutePaths.HOME}
          />
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage