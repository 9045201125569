import React from "react";
import TextComponent, {
  TextComponentType
} from "../../ui/pages/project_detailed_page/info_components/text_component/TextComponent";
import MediaComponentWrapper, {
  MediaComponentStructure
} from "../../ui/pages/project_detailed_page/info_components/media_component/MediaComponentWrapper";
import ImageComponent
  from "../../ui/pages/project_detailed_page/info_components/media_component/image_component/ImageComponent";
import VimeoPlayer, {
  VideoPauseOnHideConfig
} from "../../ui/pages/project_detailed_page/info_components/media_component/vimeo_player/VimeoPlayer";
import Credits from "../../ui/pages/project_detailed_page/info_components/credits/Credits";

const SignaxPage: React.FC = () => {
  return(
    <>
      <TextComponent
        type={TextComponentType.H3_LONG}
        rusText={'Во многом интерфейс состоит из сложных элементов — это различные многофункциональные таблицы, файловые вьюеры и графики.'}
        engText={'In many ways, the interface consists of complex elements — a variety of multi-function tables, file viewers, and graphics.'}
      />
      <MediaComponentWrapper
        structure={MediaComponentStructure.DOUBLE_MEDIA}
        thirdChild={<ImageComponent src={'https://i.imgur.com/ScgAWPn.jpg'} />}
      >
        <ImageComponent src={'https://i.imgur.com/uxqCBq6.jpg'}/>
        <ImageComponent src={'https://i.imgur.com/QyxBZxB.jpg'}/>
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'Среда общих данных сделана с учетом преимуществ и недостатков программного обеспечения из смежной сферы. В первую очередь анализировались облачные хранилища – Google Drive и другие. Специфика проекта предполагает множество очень узконаправленных задач при проектировании интерфейса, поэтому в целом продукт уникален.'}
        engText={'Shared data environment is made taking into account the advantages and disadvantages of software from the related sphere. First of all, cloud storages — Google Drive and others — were analyzed. The specificity of the project implies a lot of very narrowly focused tasks when designing the interface, so in general the product is unique.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/jy2vkJP.jpg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'Весь функционал десктопных сервисов полностью адаптирован под мобильные устройства. Это в том числе является конкурентным преимуществом решения.'}
        engText={'The entire functionality of desktop services is fully adapted for mobile devices. This is also a competitive advantage of the solution.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/FbVatrT.jpg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper
        structure={MediaComponentStructure.WITH_TEXT}
        engText={'<p>The design system includes more than 140 unique icons, 16 types of buttons, various inputs, dropdowns and components of tables and lists.</p>About 150 screens were designed for the desktop and mobile versions of the module.'}
        rusText={'<p>В дизайн-системе более 140 уникальных иконок, 16 видов кнопок, различные инпуты, дропдауны и компоненты таблиц и списков.</p>Для десктопной и мобильной версии модуля суммарно было задизайнено около 150 экранов. '}
      >
        <VimeoPlayer videoId={'930768761'} loop={true} pauseOnHideConfig={VideoPauseOnHideConfig.FIRST_SCROLL} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <VimeoPlayer videoId={'930770240'} pauseOnHideConfig={VideoPauseOnHideConfig.FIRST_SCROLL} loop={false} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.H3_SHORT}
        rusText={'Модуль для стройконтроля использует камеры 360 и позволяет отслеживать ситуацию на стройке, оставлять замечания, загружать фотоальбомы.'}
        engText={'The construction control module uses 360 cameras and allows you to monitor the situation on the construction site, leave comments, upload photo albums.'}
      />
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'Для всех модулей используется единая основная дизайн-система + локальные компоненты.'}
        engText={'A single core design system + local components are used for all modules.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/hsyVzIo.jpg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'Модуль также полностью адаптирован под мобильные устройства — более половины случае использования именно со смартфонов или планшетов.'}
        engText={'The module is also fully adapted to mobile devices — more than half of the use cases are from smartphones or tablets.'}
      />
      <MediaComponentWrapper
        structure={MediaComponentStructure.WITH_TEXT}
        engText={'The interface design of the construction control service contains more than 80 screens for the desktop and mobile version.'}
        rusText={'Дизайн интерфейса сервиса строительного контроля содержит более 80 экранов для десктопной и мобильной версии.'}
      >
        <ImageComponent src={'https://i.imgur.com/YnhLedc.jpg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/i6GGof6.jpg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.H3_SHORT}
        rusText={'Также по продуктам были подготовлены маркетинговые материалы — буклеты, баннеры и прочее.'}
        engText={'Marketing materials were also prepared for the products — booklets, banners, etc.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/nQOzm70.jpg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/x4r6bwg.jpg'} />
      </MediaComponentWrapper>
      <Credits lines={[
        {
          rusName: 'Lead designer (UI/UX, graphic):',
          rusVal: 'Глеб Коссов',
          engName: 'Lead designer (UI/UX, graphic):',
          engVal: 'Gleb Kossov'
        },
        {
          rusName: 'Second UI/UX-designer:',
          rusVal: 'Екатерина Ершова, Дарья Беличенко',
          engName: 'Second UI/UX-designer:',
          engVal: 'Ekaterina Ershova, Darya Belichenko'
        },
        {
          engName: 'Licensed mockups:',
          engVal: 'LS graphics',
          link: 'https://www.ls.graphics/'
        }
      ]}/>
    </>
  )
}

export default SignaxPage