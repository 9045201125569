import './ToolTag.css'
import React from "react";
import spline from './spline.png'

export enum ToolTagSize {
  DESKTOP,
  MOBILE
}

export enum ToolTagType {
  PHOTOSHOP,
  TILDA,
  ADOBE_ILLUSTRATOR,
  FIGMA,
  SPLINE
}

export interface ToolTagProps {
  type: ToolTagType;
  size: ToolTagSize;
}

const ToolTag: React.FC<ToolTagProps> = ({ type, size  }) => {
  return(
    (() => {
      switch (type) {
        case ToolTagType.PHOTOSHOP:
          return (
            <svg width={size === ToolTagSize.DESKTOP ? 48 : 28} height={size === ToolTagSize.DESKTOP ? 48 : 28} viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_173_2333)">
                <path d="M37.2683 0.971069H10.7316C4.86269 0.971069 0.10498 5.87592 0.10498 11.9264V37.8206C0.10498 43.871 4.86269 48.7759 10.7316 48.7759H37.2683C43.1373 48.7759 47.895 43.871 47.895 37.8206V11.9264C47.895 5.87592 43.1373 0.971069 37.2683 0.971069Z" fill="#001D23"/>
                <path d="M37.2682 3.25208H10.7315C6.08452 3.25208 2.31738 7.13572 2.31738 11.9264V37.8207C2.31738 42.6114 6.08452 46.495 10.7315 46.495H37.2682C41.9152 46.495 45.6824 42.6114 45.6824 37.8207V11.9264C45.6824 7.13572 41.9152 3.25208 37.2682 3.25208Z" fill="#001D23"/>
                <path d="M12.0435 14.6898C12.0435 14.5153 12.0711 14.4617 12.2116 14.4617C13.3643 14.4332 15.5835 14.375 17.7196 14.375C23.3958 14.375 25.3638 17.6196 25.3638 20.7776C25.3638 25.4717 21.8504 27.4698 17.5227 27.4698H15.9242V33.1721C15.9242 33.317 15.8678 33.4002 15.7029 33.4002H12.2393C12.0988 33.4002 12.0435 33.3135 12.0435 33.1721V14.6898ZM15.9242 23.9024C16.4021 23.9309 16.7107 23.9309 17.4984 23.9309C19.7186 23.9309 21.4886 23.2353 21.4886 20.8597C21.4886 19.0349 20.2806 17.8762 17.838 17.8762C16.9386 17.8762 16.1786 17.9047 15.9264 17.9344L15.9242 23.9024Z" fill="#01C8FE"/>
                <path d="M32.5844 21.9317C31.2635 21.9317 30.842 22.4244 30.842 22.9171C30.842 23.4097 31.2082 23.7861 32.8333 24.4236C36.2339 25.6986 37.3014 27.0604 37.3014 29.1166C37.3014 31.8697 35.2217 33.6944 31.5124 33.6944C29.7989 33.6944 28.3043 33.3751 27.4381 32.8836C27.3785 32.8562 27.328 32.8113 27.2929 32.7545C27.2579 32.6977 27.2399 32.6315 27.2412 32.5642V29.4348C27.2412 29.2603 27.3518 29.1736 27.4625 29.2603C28.7557 30.1008 30.3287 30.419 31.5932 30.419C32.9141 30.419 33.5048 30.0427 33.5048 29.4348C33.5048 28.884 33.0269 28.478 31.3686 27.8382C28.1937 26.6213 27.1538 25.2299 27.1538 23.144C27.1538 20.8847 28.9238 18.7122 32.6331 18.7122C34.2924 18.7122 35.5845 18.9403 36.2837 19.2915C36.35 19.3258 36.4044 19.3803 36.4395 19.4477C36.4745 19.5151 36.4885 19.592 36.4795 19.6679V22.5646C36.4795 22.7391 36.3689 22.8543 36.1985 22.7676C35.2836 22.2795 33.8455 21.9317 32.5844 21.9317Z" fill="#01C8FE"/>
              </g>
              <defs>
                <clipPath id="clip0_173_2333">
                  <rect width="48" height="48" fill="white" transform="translate(0 0.625488)"/>
                </clipPath>
              </defs>
            </svg>
          );
        case ToolTagType.TILDA:
          return (
            <svg width={size === ToolTagSize.DESKTOP ? 48 : 28} height={size === ToolTagSize.DESKTOP ? 48 : 28} viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_173_2335)">
                <path d="M8.5 0.625488H39.5C44.2 0.625488 48 4.52292 48 9.34344V39.9075C48 44.7281 44.2 48.6255 39.5 48.6255H8.5C3.8 48.6255 0 44.7281 0 39.9075V9.34344C0 4.52292 3.8 0.625488 8.5 0.625488Z" fill="#1D1D1D"/>
                <g clipPath="url(#clip1_173_2335)">
                  <path d="M24.8273 22.7101V34.4968H22.6287V21.8828L24.8273 22.7101Z" fill="white"/>
                  <path d="M16.0439 21.6109C16.0439 18.5962 17.6873 16.9529 19.6139 16.9529C21.5406 16.9529 21.8126 17.2249 23.9999 18.3242C26.1873 19.4235 26.7426 19.6955 27.8419 19.6955C28.9413 19.6955 29.4853 18.8682 29.4853 17.2249H31.4006C31.6726 20.5115 29.7573 21.8829 27.8306 21.8829C25.9039 21.8829 25.3599 21.6109 23.4446 20.5115C21.5293 19.4122 20.9739 19.1402 19.8746 19.1402C18.7753 19.1402 18.2313 19.9675 17.9593 21.8829L16.0439 21.6109Z" fill="white"/>
                  <path d="M24 41.6255C14.6273 41.6255 7 33.9982 7 24.6255C7 15.2528 14.6273 7.62549 24 7.62549C33.3727 7.62549 41 15.2528 41 24.6255C41 33.9982 33.3727 41.6255 24 41.6255ZM24 8.72482C15.228 8.72482 8.09933 15.8535 8.09933 24.6255C8.09933 33.3975 15.228 40.5262 24 40.5262C32.772 40.5262 39.9007 33.3975 39.9007 24.6255C39.9007 15.8535 32.772 8.72482 24 8.72482Z" fill="white"/>
                </g>
              </g>
              <defs>
                <clipPath id="clip0_173_2335">
                  <rect width="48" height="48" fill="white" transform="translate(0 0.625488)"/>
                </clipPath>
                <clipPath id="clip1_173_2335">
                  <rect width="34" height="34" fill="white" transform="translate(7 7.62549)"/>
                </clipPath>
              </defs>
            </svg>
          );
        case ToolTagType.FIGMA:
          return (
            <svg width={size === ToolTagSize.DESKTOP ? 48 : 28} height={size === ToolTagSize.DESKTOP ? 48 : 28} viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.0002 17.6255H18.0002C14.1342 17.6255 11.0002 14.4915 11.0002 10.6255C11.0002 6.75949 14.1342 3.62549 18.0002 3.62549H26.0002V17.6255Z" fill="#E64A19"/>
              <path d="M25.0002 31.6255H18.0002C14.1342 31.6255 11.0002 28.4915 11.0002 24.6255C11.0002 20.7595 14.1342 17.6255 18.0002 17.6255H25.0002V31.6255Z" fill="#7C4DFF"/>
              <path d="M18.0002 45.6255C14.1342 45.6255 11.0002 42.4915 11.0002 38.6255C11.0002 34.7595 14.1342 31.6255 18.0002 31.6255H25.0002V38.6255C25.0002 42.4915 21.8662 45.6255 18.0002 45.6255Z" fill="#66BB6A"/>
              <path d="M31.9998 17.6255H24.9998V3.62549H31.9998C35.8658 3.62549 38.9998 6.75949 38.9998 10.6255C38.9998 14.4915 35.8658 17.6255 31.9998 17.6255Z" fill="#FF7043"/>
              <path d="M31.9998 31.6255C35.8658 31.6255 38.9998 28.4915 38.9998 24.6255C38.9998 20.7595 35.8658 17.6255 31.9998 17.6255C28.1338 17.6255 24.9998 20.7595 24.9998 24.6255C24.9998 28.4915 28.1338 31.6255 31.9998 31.6255Z" fill="#29B6F6"/>
            </svg>
          );
        case ToolTagType.ADOBE_ILLUSTRATOR:
          return (
            <svg width={size === ToolTagSize.DESKTOP ? 48 : 28} height={size === ToolTagSize.DESKTOP ? 48 : 28} viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_173_2334)">
                <path d="M8.5 0.625488H39.5C44.2 0.625488 48 4.52292 48 9.34344V39.9075C48 44.7281 44.2 48.6255 39.5 48.6255H8.5C3.8 48.6255 0 44.7281 0 39.9075V9.34344C0 4.52292 3.8 0.625488 8.5 0.625488Z" fill="#330000"/>
                <path d="M23.2001 29.3434H15.7601L14.2401 34.1639C14.2001 34.3485 14.0401 34.4716 13.8601 34.4511H10.1001C9.88007 34.4511 9.82007 34.328 9.88007 34.0819L16.3201 15.0665C16.3801 14.8614 16.4401 14.6357 16.5201 14.3896C16.6001 13.9588 16.6401 13.5075 16.6401 13.0562C16.6201 12.9537 16.7001 12.8511 16.8001 12.8306H21.9801C22.1401 12.8306 22.2201 12.8921 22.2401 12.9947L29.5401 34.1229C29.6001 34.3485 29.5401 34.4511 29.3401 34.4511H25.1601C25.0201 34.4716 24.8801 34.369 24.8401 34.2255L23.2001 29.3434ZM16.9201 25.1793H22.0001C21.8801 24.7485 21.7201 24.2357 21.5401 23.7024C21.3601 23.1485 21.1801 22.5537 21.0001 21.9383C20.8001 21.3024 20.6201 20.687 20.4201 20.0511C20.2201 19.4152 20.0401 18.8203 19.8801 18.2255C19.7201 17.6511 19.5801 17.1178 19.4401 16.6255H19.4001C19.2201 17.5075 19.0001 18.3896 18.7201 19.2716C18.4201 20.2562 18.1201 21.2819 17.8001 22.3075C17.5201 23.3537 17.2201 24.3178 16.9201 25.1793ZM34.0001 16.4203C33.3401 16.4408 32.7001 16.1742 32.2201 15.7024C31.7601 15.1896 31.5201 14.5126 31.5401 13.8152C31.5201 13.1178 31.7801 12.4614 32.2601 11.9896C32.7401 11.5178 33.3801 11.2716 34.0401 11.2716C34.8201 11.2716 35.4201 11.5178 35.8601 11.9896C36.3001 12.4819 36.5401 13.1383 36.5201 13.8152C36.5401 14.5126 36.3001 15.1896 35.8201 15.7024C35.3601 16.1947 34.6801 16.4613 34.0001 16.4203ZM31.7601 34.2255V18.4306C31.7601 18.2255 31.8401 18.1434 32.0201 18.1434H35.9801C36.1601 18.1434 36.2401 18.246 36.2401 18.4306V34.2255C36.2401 34.4511 36.1601 34.5537 35.9801 34.5537H32.0601C31.8601 34.5537 31.7601 34.4306 31.7601 34.2255Z" fill="#FF9A00"/>
              </g>
              <defs>
                <clipPath id="clip0_173_2334">
                  <rect width="48" height="48" fill="white" transform="translate(0 0.625488)"/>
                </clipPath>
              </defs>
            </svg>
          );
        case ToolTagType.SPLINE:
          return (
            <img alt={'spline'} src={spline} style={{ width: size === ToolTagSize.DESKTOP ? 48 : 28, height: size === ToolTagSize.DESKTOP ? 48 : 28 }}/>
          )
      }
    })()
  )
}

export default ToolTag