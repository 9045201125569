import React, {useEffect, useMemo} from 'react';
import {RootStoreState} from "./redux/ReduxStore";
import {useDispatch, useSelector} from "react-redux";
import {ColorTheme} from "./constants/ColorTheme";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {RoutePaths} from "./constants/RoutePaths";
import AboutPage from "./ui/pages/about_page/AboutPage";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "./constants/MediaQueries";
import HomePage from "./ui/pages/home_page/HomePage";
import ArchivePage from "./ui/pages/archive_page/ArchivePage";
import NotFoundPage from "./ui/pages/not_found_page/NotFoundPage";
import BurgerMenu from "./ui/shared_components/burger/burger_menu/BurgerMenu";
import Header from "./ui/shared_components/header/Header";
import MaterialsPage from "./ui/pages/materials/MaterialsPage";
import BurgerButton from "./ui/shared_components/burger/burger_button/BurgerButton";
import Footer from "./ui/shared_components/footer/Footer";
import MetaThemeManager from "./utils/managers/MetaThemeManager";
import ProjectDetailedPage from "./ui/pages/project_detailed_page/ProjectDetailedPage";
import MetaTagsManager from "./utils/managers/MetaTagsManager";
import {
  checkIfDetailedProjectExists,
  checkIfPathBelongsToProjectDetailedPage, extractProjectIdFromPath
} from "./utils/project_actions/ProjectActions";
import {ProjectData} from "./project_data/ProjectData";
import {setIsBurgerShown} from "./redux/BurgerReducer";
import styled from "styled-components";

const StyledMainContentWrapper = styled.div<{
  $location: string;
  $desktop: boolean;
  $tablet: boolean;
  $validProductPage: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  min-height: ${props => props?.$desktop 
          ? 'calc(100vh - 488px)' 
          : props?.$tablet 
                  ? 'calc(100vh - 488px)' 
                  : undefined
  };
  padding: ${props => props?.$desktop
          ? '0 43px 128px'
          : props?.$tablet
                  ? '0 43px 129px'
                  : '0 16px 64px'
  };
  margin-top: ${props => props?.$desktop
          ? '0'
          : props?.$tablet
                  ? '80px'
                  : '60px'
  };
  overflow: ${props => props?.$location === RoutePaths.ARCHIVE ? undefined : 'hidden'};

  ${props => props?.$validProductPage && 'padding: 0 !important'};
  ${props => props?.$validProductPage && 'margin: 0 !important'};
`

const AppWrapper = styled.div<{ $location: string, $colorTheme: ColorTheme }>`
  position: relative;
  overflow: ${props => props?.$location === RoutePaths.ARCHIVE ? undefined : undefined};
  max-height: ${props => props?.$location === RoutePaths.ARCHIVE ? '100vh' : undefined};
  transition: 0.2s all ease-in-out;
  background-color: ${props => props?.$colorTheme === ColorTheme.WHITE ? 'var(--main-bg)' : 'var(--main-bg-dark)'};
`

const App: React.FC = () => {
  const isBurgerOpened = useSelector((state: RootStoreState) => state.burger.isOpened)
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)
  const location = useLocation()

  const isTablet = useMediaQuery({ query: MediaQueries.TABLET })
  const isDesktop = useMediaQuery({ query: MediaQueries.DESKTOP })
  const dispatch = useDispatch()

  const isValidProductPage = useMemo(() => {
    return checkIfPathBelongsToProjectDetailedPage(location?.pathname)
      && checkIfDetailedProjectExists(extractProjectIdFromPath(location?.pathname), ProjectData)
  }, [location])

  useEffect(() => {
    window.scroll({top: 0})
  }, [location]);

  useEffect(() => {
    dispatch(setIsBurgerShown(false))
  }, [isDesktop]);

  useEffect(() => {
    if (isBurgerOpened) {
      document.body.classList.add('hidden');
    } else {
      document.body.classList.remove('hidden');
    }
  }, [isBurgerOpened]);

  return (
    <AppWrapper
      $colorTheme={currTheme}
      $location={location.pathname}
    >
      <BurgerButton/>
      <Header/>
      <BurgerMenu/>
      <MetaThemeManager/>
      <MetaTagsManager/>
      <StyledMainContentWrapper
        $location={location.pathname}
        $desktop={isDesktop}
        $tablet={isTablet}
        $validProductPage={isValidProductPage}
      >
        <Routes>
          <Route path="/" element={<Navigate to={RoutePaths.HOME} />} />
          <Route path={RoutePaths.HOME} element={<HomePage />} />
          <Route path={RoutePaths.ARCHIVE} element={<ArchivePage />} />
          <Route path={RoutePaths.ABOUT} element={<AboutPage />} />
          <Route path={RoutePaths.MATERIALS} element={<MaterialsPage />} />
          <Route path={RoutePaths.PROJECT_DETAILED} element={<ProjectDetailedPage />}/>
          <Route path={RoutePaths.NOT_FOUND} element={<NotFoundPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </StyledMainContentWrapper>
      <Footer/>
    </AppWrapper>
  );
}

export default App;
