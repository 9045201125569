import React from "react";
import MediaComponentWrapper, {
  MediaComponentStructure
} from "../../ui/pages/project_detailed_page/info_components/media_component/MediaComponentWrapper";
import VimeoPlayer, {
  VideoPauseOnHideConfig
} from "../../ui/pages/project_detailed_page/info_components/media_component/vimeo_player/VimeoPlayer";
import TextComponent, {
  TextComponentType
} from "../../ui/pages/project_detailed_page/info_components/text_component/TextComponent";
import ImageComponent
  from "../../ui/pages/project_detailed_page/info_components/media_component/image_component/ImageComponent";
import Credits from "../../ui/pages/project_detailed_page/info_components/credits/Credits";

const HPT_Page: React.FC = () => {
  return(
    <>
      <TextComponent
        type={TextComponentType.H3_LONG}
        rusText={'Стиль продукта основан на простой «мультяшной» плоской графике и сочетании белого, чёрного и полутонов. Логотип состоит из надписи и двух элементов айдентики, которые могут использоваться в том числе и по отдельности.'}
        engText={'The product style is based on simple «cartoon» flat graphics and a combination of white, black and halftones. Logo consists of the text part and two identity elements that can be used separately.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.DOUBLE_MEDIA}>
        <ImageComponent src={'https://i.imgur.com/5pF1L3o.png'}/>
        <VimeoPlayer videoId={'919664647'} pauseOnHideConfig={VideoPauseOnHideConfig.FIRST_SCROLL} loop={true}/>
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'Образ айдентики элементарен — дымное облачко в форме букв в слове hOOkah. Графический паттерн очень легко воспринимается, ассоциируется и запоминается. Широкие «ломанные» буквы шрифта Unbounded также дополняют общий стиль и используются как паттерн.'}
        engText={'The image of identity is elementary — a letter-shaped smoke cloud as a part of hOOkah. Graphic patterns are very easy to perceive, associate and remember. Wide «broken» letters of Unbounded font also complement the overall style and can be used as a pattern.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.DOUBLE_MEDIA}>
        <ImageComponent src={'https://i.imgur.com/wv7Ehfk.png'}/>
        <ImageComponent src={'https://i.imgur.com/CQve3ek.png'}/>
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'Цвета свободно адаптируются под ситуацию и позволяют стилю выигрышно выделяться на светлых и тёмных носителях.'}
        engText={'Colors are freely adaptable to any situation and allow the style to stand out excellent on light and dark physical media.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.DOUBLE_MEDIA}>
        <ImageComponent src={'https://i.imgur.com/PtD6fcb.jpg'}/>
        <ImageComponent src={'https://i.imgur.com/Irw2kNJ.jpg'}/>
      </MediaComponentWrapper>
      <MediaComponentWrapper
        structure={MediaComponentStructure.WITH_TEXT}
        rusText={'Упаковки товаров в основном яркие и цветные, что отлично сочетается с монотонной основной палитрой интернет-магазина. <p>Анимация делает интерфейс сайта живым и предсказуемым. Тем не менее, все эффекты используются оправданно и не во вред пользовательскому опыту.</p>'}
        engText={'Product packaging is mostly bright and colorful, which blends perfectly with the monotonous main palette of the online store.<p>Animations make the interface lively and predictable. Nevertheless, all effects are used reasonably and don’t makе user experience worse.</p>'}
      >
        <VimeoPlayer videoId={'920001636'} pauseOnHideConfig={VideoPauseOnHideConfig.FIRST_SCROLL} loop={false} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/VhTyYge.jpg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'Вопреки мнению многих дизайнеров, использовать чистый чёрный и белый цвета в дизайне интерфейсов можно и иногда нужно.'}
        engText={'Contrary to the opinion of many designers, it is possible (and sometimes necessary) to use pure black and white colors in UI-design.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.FULL_WIDTH}>
        <ImageComponent
          src={'https://i.imgur.com/Bx6gWby.jpg'}
          withParallax={true}
          backgroundFill={'#F2F2F2FF'}
          scale={1.15}
          parallaxStrength={0.06}
        />
      </MediaComponentWrapper>
      <MediaComponentWrapper
        structure={MediaComponentStructure.WITH_TEXT}
        rusText={'При адаптации дизайна для разных экранов был использован смешанный подход. Таким образом удалось реализовать преимущества и адаптивного, и отзывчивого дизайна. <p>Дизайн сайта на мобильных устройствах основан на паттернах проектирования приложений.</p>'}
        engText={'When adapting the design for different screens, a mixed approach was used. This way we managed to realize the advantages of both adaptive and responsive design.<p>Mobile web-design is based on app design patterns.\n</p>'}
      >
        <VimeoPlayer videoId={'919998899'} pauseOnHideConfig={VideoPauseOnHideConfig.FIRST_SCROLL} loop={false} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/wXJZmEC.jpg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper
        structure={MediaComponentStructure.WITH_TEXT}
        rusText={'Качественно проработанный мобильный интерфейс выражается в мелочах, которые улучшают пользовательский опыт. Так, на сайте логотип замещается кнопкой корзины с индикацией количества товаров и их суммарной стоимости.<p>При «прямом» адаптировании кнопка корзины была бы закреплена сбоку, как на десктопе. Но на мобильных устройствах особенно важно сохранять место.</p>'}
        engText={'A well-designed mobile interface is expressed in small details that improve user experience. For instance, the website logo can be replaced by the shopping cart button with an indication of the number of products and their total cost.<p>In the «direct» adaptation, the shopping cart button would be fixed on the side, like on the desktop version. But on mobile devices it is especially important to save space.</p>'}
      >
        <ImageComponent src={'https://i.imgur.com/bCgbzen.jpg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/1SvfShj.png'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'Функциональный минимализм, продвинутая анимация всех элементов взаимодействия и полноценное веб-приложение для мобильных устройств подчёркивают уникальность и совершенность сервиса. Цветовая гамма, шрифтовая пара и использование в оформлении гибких «плоских» паттернов позволяют легко масштабировать заданную айдентику на любые желаемые носители.'}
        engText={'Functional minimalism, advanced animation of all interaction elements and a full-fledged web application for mobile devices emphasize the uniqueness and perfection of the service. The color scheme, the font pair and the use of flexible «flat» patterns in the design make it easy to scale the given identity to any desired physical media.'}
      />
      <Credits lines={[
        { rusName: 'Product and UI/UX design:', rusVal: 'Глеб Коссов', engName: 'Product and UI/UX design:', engVal: 'Gleb Kossov' },
        { engName: 'Licensed mockups:', engVal: 'LS graphics', link: 'https://www.ls.graphics/' }
      ]}/>
    </>
  )
}

export default HPT_Page
