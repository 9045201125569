import './BurgerMenu.css'
import './../../../../assets/styles/colors.css'
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {useDrag} from "@use-gesture/react";
import {animated} from "react-spring";
import {ReactDOMAttributes} from "@use-gesture/react/dist/declarations/src/types";
import {RootStoreState} from "../../../../redux/ReduxStore";
import {MediaQueries} from "../../../../constants/MediaQueries";
import {setIsBurgerShown} from "../../../../redux/BurgerReducer";
import {ColorTheme} from "../../../../constants/ColorTheme";
import './../../../../assets/styles/animation_durations.css'
import MenuItemsBlock from "../../menu_items_block/MenuItemsBlock";
import MenuItem from "../../menu_item/MenuItem";
import {toggleLang} from "../../../../redux/LangReducer";
import {Lang} from "../../../../constants/Lang";
import ColorThemeSwitcher from "../../color_theme_switcher/colorThemeSwitcher";
import {AnimatePresence, motion} from 'framer-motion';

const BurgerMenu: React.FC = () => {
  const isBurgerOpened = useSelector((state: RootStoreState) => state.burger.isOpened)
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)
  const currLang = useSelector((state: RootStoreState) => state.lang)
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })
  const isTablet = useMediaQuery({ query: MediaQueries.TABLET })

  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsBurgerShown(false))
  }, [dispatch, location, isMobile]);

  const bind = useDrag(({ movement: [mx] }) => {
    if (mx > 8) {
      dispatch(setIsBurgerShown(false))
    }
  }) as unknown as (...args: any[]) => ReactDOMAttributes;

  return(
    <>
      <AnimatePresence>
        {isBurgerOpened && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className={`
              blurring_div 
              ${currTheme === ColorTheme.DARK ? 'dark' : 'white'}
            `}
            onTouchEnd={e => {
              e.preventDefault()
              dispatch(setIsBurgerShown(false))
            }}
            onClickCapture={e => {
              e.preventDefault()
              dispatch(setIsBurgerShown(false))
            }}
          />
        )}
      </AnimatePresence>
      <animated.div {...bind()}>
        <div
          className={`
            burger_menu animation-02s-all 
            ${isBurgerOpened && 'opened'} 
            ${currTheme === ColorTheme.DARK ? 'dark' : 'white'}
            ${isTablet && 'tablet'}
           `}
        >
          <div className={'selectors-wrapper'}>
            <ColorThemeSwitcher/>
            <MenuItem
              onClick={() => dispatch(toggleLang())}
              text={currLang === Lang.ENG ? 'EN' : 'RU'}
              isActive={true}
            />
          </div>
          <div style={{ paddingTop: Math.min(128, (window.innerHeight - 376 - 60) / 2) }}>
            <MenuItemsBlock containerStyle={{ alignItems: 'flex-end' }}/>
          </div>
        </div>
      </animated.div>
    </>
  )
}

export default BurgerMenu
