import React, {useMemo} from "react";
import {MediaQueries} from "../../../constants/MediaQueries";
import {useMediaQuery} from "react-responsive";
import {ProjectInfo} from "../../../types/ProjectInfoTypes";
import {ProjectData} from "../../../project_data/ProjectData";
import ProjectCardArchive from "../../shared_components/project_card/project_card_archive/ProjectCardArchive";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../redux/ReduxStore";
import {ColorTheme} from "../../../constants/ColorTheme";
import styled from "styled-components";

const ArchivePageWrapper = styled.div<{ $desktop: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${props => props?.$desktop ? 64 : 48}px;
  padding-top: 16px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1354px;
`

const YearSectionWrapper = styled.div<{ $mobile: boolean }>`
  display: flex;
  flex-direction: ${props => props?.$mobile ? 'column' : 'row'};
  gap: ${props => props?.$mobile ? 32 : 64}px;
  width: 100%;
`

const ProjectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`

const StickyYear = styled.h1<{
  $colorTheme: ColorTheme,
  $desktop: boolean;
  $tablet: boolean
}>`
  top: ${props => props?.$desktop ? 0 : props?.$tablet ? 16 : 10
  }px;
  position: sticky;
  max-height: 50px;
  max-width: 200px;
  z-index: 10;
  transition: 0.2s all ease-in-out;
  background-color: ${props => props?.$colorTheme === ColorTheme.WHITE ? 'var(--main-white, #FFF)' : 'var(--main-bg-dark, #1D1D1DFF)'};
  color: ${props => props?.$colorTheme === ColorTheme.WHITE ? 'var(--text-gray-light, #9DA1AE)' : 'var(--text-primary-dark, #FFFFFF)'};
`

const ArchivePage: React.FC = () => {
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })
  const isDesktop = useMediaQuery({ query: MediaQueries.DESKTOP })
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)
  const isTablet = useMediaQuery({ query: MediaQueries.TABLET })

  const projects = useMemo(() => {
    return(
      Object.entries(
        ProjectData.reduce<{[year: number]: ProjectInfo[]}>((acc, p) => {
          const year = p.year;
          if (!acc[year]) {
            acc[year] = [];
          }
          acc[year].push(p);
          return acc;
        }, {})
      )
        .sort(([yearA], [yearB]) => parseInt(yearB) - parseInt(yearA))
    )
  }, []);

  return(
    <ArchivePageWrapper $desktop={isDesktop}>
      {projects.map(([year, projects]) => (
        <YearSectionWrapper key={year} $mobile={!isDesktop}>
          <StickyYear
            $colorTheme={currTheme}
            $desktop={isDesktop}
            $tablet={isTablet}
            className={`${isMobile ? 'mobile-h1-text' : 'desktop-h1-text'}`}
          >
            {year}
          </StickyYear>
          <ProjectsWrapper>
            {projects.map(p => (
              <ProjectCardArchive
                {...p}
                key={`${p?.id}-arch`}
                isImplemented={p?.page !== undefined}
              />
            ))}
          </ProjectsWrapper>
        </YearSectionWrapper>
      ))}
    </ArchivePageWrapper>
  )
}

export default ArchivePage
