import './Logo.css'
import React, {useEffect, useState} from "react";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/MediaQueries";
import {Link, useLocation} from "react-router-dom";
import {RoutePaths} from "../../../../constants/RoutePaths";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../../redux/ReduxStore";
import useHover from "../../../../utils/hooks/UseHoverHook";

const Logo: React.FC = () => {
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })
  const isDesktop = useMediaQuery({ query: MediaQueries.DESKTOP })

  const isBurgerOpened = useSelector((state: RootStoreState) => state.burger.isOpened)
  const {isHovered, ...hoverHandlers} = useHover()
  const [scrollVal, setScrollVal] = useState(0)
  const location = useLocation()

  useEffect(() => {
    const handleResize = () => {
      setScrollVal(window.scrollY)
    };

    window.addEventListener('scroll', handleResize);

    return () => {
      window.removeEventListener('scroll', handleResize);
    };
  }, []);

  const isHidden = location.pathname === RoutePaths.ARCHIVE && scrollVal > 100 && !isDesktop

  return(
    <Link
      to={RoutePaths.HOME}
      style={{ textDecoration: 'none', maxHeight: isMobile ? 36 : 48, color: 'inherit', opacity: isHidden ? 0 : 1 }}
    >
      <svg
        className={`animation-02s-all logo-wrapper ${isHovered && navigator?.userAgent?.includes('Chrome') && 'hovered'}`}
        opacity={isBurgerOpened ? 0 : 1}
        width={isMobile ? '36' : '48'}
        height={isMobile ? '36' : '48'}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...hoverHandlers}
      >
        <circle cx="24" cy="24" r="24" fill="#A30EFF" fillOpacity="0.94"/>
        <circle cx="24" cy="24" r="24" fill="url(#paint0_radial_34_3180)"/>
        <circle cx="24" cy="24" r="24" fill="url(#paint1_radial_34_3180)"/>
        <circle cx="24" cy="24" r="24" fill="url(#paint2_radial_34_3180)"/>
        <circle cx="24" cy="24" r="24" fill="url(#paint3_radial_34_3180)"/>
        <circle cx="24" cy="24" r="24" fill="url(#paint4_radial_34_3180)"/>
        <circle cx="24" cy="24" r="24" fill="url(#paint5_radial_34_3180)"/>
        <circle cx="24" cy="24" r="24" fill="url(#paint6_radial_34_3180)"/>
        <circle cx="24" cy="24" r="24" fill="url(#paint7_radial_34_3180)"/>
        <defs>
          <radialGradient id="paint0_radial_34_3180" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 22.5) rotate(117.759) scale(32.2064)">
            <stop offset="0.620731" stopColor="#631FFF" stopOpacity="0"/>
            <stop offset="1" stopColor="#631FFF"/>
          </radialGradient>
          <radialGradient id="paint1_radial_34_3180" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(29 2) rotate(96.9272) scale(53.8934 45.1993)">
            <stop offset="0.619802" stopColor="#6716FF"/>
            <stop offset="1" stopColor="#5E1CFF" stopOpacity="0"/>
          </radialGradient>
          <radialGradient id="paint2_radial_34_3180" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30 5.5) rotate(112.41) scale(52.4619 28.0857)">
            <stop offset="0.120702" stopColor="#0EFF7D"/>
            <stop offset="0.431793" stopColor="#0FFE7E" stopOpacity="0.24"/>
            <stop offset="1" stopColor="#0EFF7D"/>
          </radialGradient>
          <radialGradient id="paint3_radial_34_3180" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(40 16.5) rotate(120.579) scale(51.1077 35.6834)">
            <stop offset="0.199078" stopColor="#D7FF15"/>
            <stop offset="0.444117" stopColor="#D7FF15" stopOpacity="0.5"/>
            <stop offset="0.768144" stopColor="#D7FF15" stopOpacity="0"/>
          </radialGradient>
          <radialGradient id="paint4_radial_34_3180" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.5 50) rotate(-149.281) scale(40.131 18.4165)">
            <stop stopColor="#0EDFFF"/>
            <stop offset="0.544478" stopColor="#13E5F6" stopOpacity="0.3"/>
            <stop offset="1" stopColor="#2AFFD2" stopOpacity="0"/>
          </radialGradient>
          <radialGradient id="paint5_radial_34_3180" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14 41) rotate(-95.1944) scale(33.1361 25.8064)">
            <stop stopOpacity="0.79"/>
            <stop offset="1" stopOpacity="0.03"/>
          </radialGradient>
          <radialGradient id="paint6_radial_34_3180" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24 45) rotate(-33.4533) scale(33.5597 26.1363)">
            <stop offset="0.183413" stopOpacity="0.81"/>
            <stop offset="1" stopOpacity="0"/>
          </radialGradient>
          <radialGradient id="paint7_radial_34_3180" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19 44.5) rotate(-159.114) scale(40.6725 21.9417)">
            <stop stopColor="#0EDFFF" stopOpacity="0.94"/>
            <stop offset="0.651053" stopColor="#13E5F6" stopOpacity="0.3"/>
            <stop offset="1" stopColor="#2AFFD2" stopOpacity="0"/>
          </radialGradient>
        </defs>
      </svg>
    </Link>
  )
}

export default Logo
