import './ProjectCardHome.css'
import './../../../../assets/styles/animation_durations.css'
import React from "react";
import {ColorTheme} from "../../../../constants/ColorTheme";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {RoutePaths} from "../../../../constants/RoutePaths";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/MediaQueries";
import {RootStoreState} from "../../../../redux/ReduxStore";
import {ProjectInfoWithImage} from "../../../../types/ProjectInfoTypes";
import {Lang} from "../../../../constants/Lang";
import useHover from "../../../../utils/hooks/UseHoverHook";

const ProjectCardHome: React.FC<ProjectInfoWithImage> = ({
  previewImage,
  engName,
  id,
  descriptionHomeRUS,
  descriptionHomeENG,
  backgroundColorOnHoverLight,
  backgroundColorOnHoverDark,
  backgroundColorDark,
  backgroundColorLight
}) => {
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)
  const currLang = useSelector((state: RootStoreState) => state.lang)
  const isDesktop = useMediaQuery({ query: MediaQueries.DESKTOP })
  const {isHovered, ...hoverHandlers} = useHover()

  return(
    <Link
      to={RoutePaths.PROJECT_DETAILED.replace(':id', id)}
      style={{ textDecoration: 'none' }}
      className={`project-card-wrapper animation-02s-all ${isHovered && 'hovered'}`}
      {...hoverHandlers}
    >
      <div
        className={`project-img-preview-wrapper animation-04s-all`}
        style={{
          backgroundColor: currTheme === ColorTheme.DARK
            ? isHovered ? backgroundColorOnHoverDark : backgroundColorDark
            : isHovered ? backgroundColorOnHoverLight : backgroundColorLight
        }}
      >
        <img
          src={previewImage}
          alt="img"
          className={`project-img-preview ${isHovered && 'hovered'} animation-04s-all`}
        />
      </div>
      <div className="project-info">
        <div className={`animation-02s-all ${isDesktop ? 'desktop-h2-text' : 'mobile-h2-text'} ${currTheme === ColorTheme.WHITE ? 'text-primary-text' : 'text-primary-text-dark'}`}>
          {engName}
        </div>
        <div className={`animation-02s-all ${isDesktop ? 'desktop-card-description-text' : 'mobile-card-description-text'} ${currTheme === ColorTheme.WHITE ? 'text-gray-main-text' : 'text-gray-main-text-dark'}`}>
          {currLang === Lang.ENG ? descriptionHomeENG : descriptionHomeRUS}
        </div>
      </div>
    </Link>
  )
}

export default ProjectCardHome
