import './VimeoPlayer.css'
import React, {useEffect, useRef, useState} from 'react';
import Vimeo from '@vimeo/player';
import axios from "axios";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../../../constants/MediaQueries";

export enum VideoPauseOnHideConfig {
  NEVER,
  ALWAYS,
  FIRST_SCROLL
}

interface VimeoPlayerProps {
  videoId: string;
  loop: boolean;
  pauseOnHideConfig: VideoPauseOnHideConfig
}

const VimeoPlayer: React.FC<VimeoPlayerProps> = ({ videoId, pauseOnHideConfig, loop }) => {
  const playerComponentRef = useRef<HTMLDivElement | null>(null);
  const playerInstanceRef = useRef<Vimeo | null>(null);
  const scrollObserverRef = useRef<IntersectionObserver | null>(null)

  const [isLoading, setIsLoading] = useState(true);
  const [videoDuration, setVideoDuration] = useState(10)
  const [aspectRatio, setAspectRatio] = useState('')
  const [hasScrolledToVideo, setHasScrolledToVideo] = useState(false)
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })

  // player component init
  useEffect(() => {
    let player: Vimeo | null = null;

    const initPlayer = async () => {
      if (!playerComponentRef.current) {
        return;
      }

      player = new Vimeo(playerComponentRef.current, {
        url: `https://vimeo.com/${videoId}`,
        autoplay: true,
        controls: false,
        loop: loop,
        muted: true
      });

      playerInstanceRef.current = player;

      await axios
        .get(`https://vimeo.com/api/v2/video/${videoId}.json`)
        .then(res => res.data[0])
        .then(vid => setAspectRatio(`${(vid.height / vid.width) * 100}%`))
        .catch(() => setAspectRatio('75%'));

      player
        ?.getDuration()
        ?.then(d => setVideoDuration(d))

      player.on('play', () => setIsLoading(false));
      player.on('playing', () => {
        setIsLoading(false)
        setHasScrolledToVideo(true)
      });
      player.on('loaded', () => setIsLoading(false));
      player.on('pause', () => setIsLoading(false));
      player.on('ended', () => {setIsLoading(false)});
      player.on('bufferstart', () => setIsLoading(true));
      player.on('bufferend', () => setIsLoading(false));

      player.on('timeupdate', (data: { seconds: number }) => {
        if (data.seconds >= videoDuration - 0.3 && !loop) {
          player?.pause()
        }
      });
    };

    initPlayer().catch(() => {});

    return () => {
      if (player) {
        player.destroy().catch(() => {});
      }
    };
  }, [videoId, loop, videoDuration]);


  // pause on hide
  useEffect(() => {
    if (pauseOnHideConfig === VideoPauseOnHideConfig.NEVER) {
      return
    }

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: isMobile ? 0.9 : 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      try {
        entries.forEach((entry) => {
          if (entry?.isIntersecting && playerInstanceRef?.current && (loop || !hasScrolledToVideo)) {
            playerInstanceRef?.current?.play()
              .then(() => setHasScrolledToVideo(true))
              .catch(() => {})
          } else if (playerInstanceRef?.current && (!hasScrolledToVideo || pauseOnHideConfig === VideoPauseOnHideConfig.ALWAYS)) {
            playerInstanceRef?.current?.pause()
              .catch(() => {})
          }
        });
      } catch (e) {
        console.log((e as Error)?.message)
      }
    }, options);

    const currentVideoRef = playerComponentRef.current;
    try {
      if (currentVideoRef) {
        // cleanup
        scrollObserverRef.current?.unobserve(currentVideoRef)
        scrollObserverRef.current?.disconnect();

        // mount new observer
        observer.observe(currentVideoRef);
        scrollObserverRef.current = observer
      }
    } catch (e) {
      console.log((e as Error)?.message)
    }

    // cleanup
    return () => {
      try {
        if (currentVideoRef) {
          scrollObserverRef.current?.unobserve(currentVideoRef)
          observer.disconnect()
        }
      } catch (e) {
        console.log((e as Error)?.message)
      }
    };
  }, [isMobile, hasScrolledToVideo, pauseOnHideConfig, loop]);


  return (
    <div
      className={'vimeo-player'}
      style={{ paddingTop: aspectRatio }}
      ref={playerComponentRef}
    />
  )
};

export default VimeoPlayer;