import './BurgerButton.css'
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStoreState} from "../../../../redux/ReduxStore";
import {toggleBurger} from "../../../../redux/BurgerReducer";
import './../../../../assets/styles/animation_durations.css'
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/MediaQueries";
import {ColorTheme} from "../../../../constants/ColorTheme";

const BurgerButton: React.FC = () => {
  const isOpened = useSelector((state: RootStoreState) => state.burger.isOpened)
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)
  const isDesktop = useMediaQuery({ query: MediaQueries.DESKTOP })
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })

  const dispatch = useDispatch()

  return(
    <div className={`burger-btn-wrapper-absolute-btn animation-02s-all ${isDesktop ? 'hidden' : !isMobile && 'tablet'}`}>
      <div
        className={`burger-btn-wrapper`}
        onClick={() => dispatch(toggleBurger())}
      >
        <div className={`animation-02s-all bar-top ${currTheme === ColorTheme.DARK && 'dark'} ${isOpened && 'inclined'}`} />
        <div className={`animation-02s-all bar-bottom ${currTheme === ColorTheme.DARK && 'dark'} ${isOpened && 'inclined'}`} />
      </div>
    </div>
  )
}

export default BurgerButton
