import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../redux/ReduxStore";
import {ColorTheme} from "../../constants/ColorTheme";

const MetaThemeManager: React.FC = () => {
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)

  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[engName=theme-color]');
    const targetThemeValue = currTheme === ColorTheme.DARK ? '#1D1D1D' : 'white'

    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', targetThemeValue);
    } else {
      const newMetaTag = document.createElement('meta');
      newMetaTag.setAttribute('name', 'theme-color');
      newMetaTag.setAttribute('content', targetThemeValue);
      document.head.appendChild(newMetaTag);
    }

    if (currTheme === ColorTheme.DARK) {
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')
    }
  }, [currTheme]);

  return <></>
}

export default MetaThemeManager