import {ProjectInfo} from "../../types/ProjectInfoTypes";

export const checkIfDetailedProjectExists = (projectId: string | undefined, projects: ProjectInfo[]): boolean => {
  if (!projectId) {
    return false
  }
  return projects.find(p => p?.id === projectId)?.page !== undefined
}

export const checkIfPathBelongsToProjectDetailedPage = (path: string | undefined): boolean =>{
  if (!path) {
    return false
  }

  return  /\/projects\/[^\/]+/.test(path)
}

export const extractProjectIdFromPath = (path: string | undefined): string => {
  if (!path) {
    return ''
  }

  try {
    return path.split('/').slice(-1)[0]
  } catch (e) {
    return ''
  }
}