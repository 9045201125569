import './StandardButton.css'
import './../../../assets/styles/typography.css'
import React from "react";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../redux/ReduxStore";
import {ColorTheme} from "../../../constants/ColorTheme";
import useHoverAndClick from "../../../utils/hooks/UseHoverAndClickHook";

export interface StandardButtonProps {
  onClick: () => void;
  text: string;
  fullWidth?: boolean;
}

const StandardButton: React.FC<StandardButtonProps> = ({ onClick, text, fullWidth }) => {
  const {isHovered, isClicked, ...eventHandlers} = useHoverAndClick()
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)

  return(
    <div style={{ display: 'flex', flexDirection: fullWidth ? 'column' : 'row', width: '100%' }}>
      <div
        className={`
        animation-02s-all noselect standard-button-wrapper text-desktop-btns
        ${currTheme === ColorTheme.WHITE
          ? isClicked ? 'clicked' : isHovered ? 'hovered' : 'plain-white'
          : isClicked ? 'clicked-dark' : isHovered ? 'hovered-dark' : 'plain-dark'
        }
        ${currTheme === ColorTheme.WHITE ? 'text-primary-text' : 'text-primary-text-dark'}
      `}
        onClick={onClick}
        {...eventHandlers}
      >
        {text}
      </div>
    </div>
  )
}

export default StandardButton