import './HomePage.css'
import React from "react";
import {MediaQueries} from "../../../constants/MediaQueries";
import {useMediaQuery} from "react-responsive";
import {ProjectData} from "../../../project_data/ProjectData";
import ProjectCardHome from "../../shared_components/project_card/project_card_home/ProjectCardHome";

const HomePage: React.FC = () => {
  const isDesktop = useMediaQuery({ query: MediaQueries.DESKTOP })
  const isTablet = useMediaQuery({ query: MediaQueries.TABLET })

  return(
    <div className={`homepage-wrapper ${isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'}`}>
      {ProjectData
        .filter(p => p.isOnHome)
        .sort((a, b) => (b?.priorityIndexHome || 0) - (a?.priorityIndexHome || 0))
        .map(p => (
          <ProjectCardHome {...p} key={`${p?.id}-home-card`} />
        ))
      }
    </div>
  )
}

export default HomePage