import MenuItem, {MenuItemProps} from "./MenuItem";
import {Link} from "react-router-dom";
import React from "react";

interface MenuItemWithLinkProps extends Omit<MenuItemProps, "onClick"> {
  to: string;
  external?: boolean;
}

const MenuItemWithLink: React.FC<MenuItemWithLinkProps> = ({ to, external, ...menuItemProps}) => {
  return (
    <Link
      to={to}
      target={external ? '_blank' : undefined}
      style={{ textDecoration: "none" }}
    >
      <MenuItem
        {...menuItemProps}
        onClick={() => {}}
      />
    </Link>
  );
};

export default MenuItemWithLink;