import './ProjectDetailedPage.css'
import React, {useMemo} from "react";
import {useParams} from "react-router-dom";
import ParallaxHeadBanner from "./parallax_head_banner/ParallaxHeadBanner";
import ShortInfoBlock from "./short_info_block/ShortInfoBlock";
import {ProjectData} from "../../../project_data/ProjectData";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../constants/MediaQueries";
import {checkIfDetailedProjectExists} from "../../../utils/project_actions/ProjectActions";
import {ColorTheme} from "../../../constants/ColorTheme";
import {Lang} from "../../../constants/Lang";
import StandardButtonWithLink from "../../shared_components/standard_button/StandardButtonWithLink";
import {RoutePaths} from "../../../constants/RoutePaths";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../redux/ReduxStore";
import TextFormatterComponent from "../../../utils/formatters/TextFormatterComponent";

const ProjectDetailedPage: React.FC = () => {
  const currLang = useSelector((state: RootStoreState) => state.lang)
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })

  const { id } = useParams<{ id: string }>()
  const projectInfo = useMemo(() => {
    try {
      return ProjectData.filter(p => p.id === id)[0]
    } catch (e) {
      return undefined
    }
  }, [id])

  const isProjectExists = useMemo(() => {
    return checkIfDetailedProjectExists(id, ProjectData)
  }, [id]);

  return(
    isProjectExists ? (
      <div className={'project-detailed-page'}>
        <ParallaxHeadBanner
          tags={projectInfo?.toolTags || []}
          image={projectInfo?.headerImage}
          name={projectInfo?.engName || ''}
        />
        <ShortInfoBlock
          buttons={projectInfo?.linkButtons || []}
          rusDescription={projectInfo?.shortDescriptionRus || ''}
          engDescription={projectInfo?.shortDescriptionEng || ''}
        />
        <div className={`project-page-wrapper ${isMobile ? 'mobile' : 'desktop'}`}>
          {projectInfo?.page}
        </div>
      </div>
    ) : (
      <div className={'project-detailed-page-not-found'}>
        <div className={'project-detailed-page-not-found-text-wrapper'}>
          <TextFormatterComponent
            classNames={`animation-02s-all ${isMobile ? 'mobile-h1-text' : 'desktop-h1-text'} ${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'}`}
            text={currLang === Lang.ENG ? 'This project is coming up soon' : 'Этот проект вот-вот появится'}
          />
          <TextFormatterComponent
            classNames={`animation-02s-all ${isMobile ? 'mobile-main-text' : 'desktop-main-text'} ${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'}`}
            text={currLang === Lang.ENG ? 'If you really want to, you can get in touch and request a showing of it directly' : 'Если очень хочется, можно связаться и запросить его показ напрямую'}
          />
          <div style={{ marginTop: 24 }} className={'noselect'}>
            <StandardButtonWithLink
              to={RoutePaths.HOME}
              text={currLang === Lang.RUS ? 'Вернуться на главную' : 'Return to home page'}
            />
          </div>
        </div>
      </div>
    )
  )
}

export default ProjectDetailedPage