import React, {useMemo} from "react";
import {formatText} from "./TextFormatter";

interface TextFormatterComponentProps {
  text: string;
  classNames: string;
  disabled?: boolean
}

const TextFormatterComponent: React.FC<TextFormatterComponentProps> = ({ text, classNames, disabled}) => {
  const formattedText = useMemo(() => {
    return !disabled ? formatText(text) : text
  }, [text, disabled])

  return(
    <div
      className={classNames}
      dangerouslySetInnerHTML={{ __html: formattedText }}
    />
  )
}

export default TextFormatterComponent