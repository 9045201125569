import './MediaComponentWrapper.css'
import React from "react";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../../constants/MediaQueries";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../../../redux/ReduxStore";
import {Lang} from "../../../../../constants/Lang";
import {ColorTheme} from "../../../../../constants/ColorTheme";
import TextFormatterComponent from "../../../../../utils/formatters/TextFormatterComponent";

export enum MediaComponentStructure {
  FULL_WIDTH,
  STANDARD,
  WITH_TEXT,
  DOUBLE_MEDIA,
}

interface MediaComponentWrapperProps extends React.PropsWithChildren {
  structure: MediaComponentStructure;
  thirdChild?: React.ReactNode;
  rusText?: string;
  engText?: string;
  withoutTextFormatting?: boolean;
}

const MediaComponentWrapper: React.FC<MediaComponentWrapperProps> = (props) => {
  const {
    thirdChild,
    rusText,
    engText,
    children,
    structure,
    withoutTextFormatting
  } = props
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })
  const currLang = useSelector((state: RootStoreState) => state.lang)
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)

  return (
    <div
      className={`
        media-component-top-level-wrapper 
        ${!isMobile && 'desktop'}
        ${structure === MediaComponentStructure.FULL_WIDTH && 'no-padding'}
      `}
    >
      <div
        className={`
        media-component-wrapper 
        ${!isMobile && 'desktop'} 
        ${structure === MediaComponentStructure.FULL_WIDTH && 'no-padding'}
        ${(structure === MediaComponentStructure.WITH_TEXT || structure === MediaComponentStructure.DOUBLE_MEDIA) && !isMobile && 'gridded'}
      `}
      >
        {children}
        {structure === MediaComponentStructure.WITH_TEXT && rusText && engText && (
          <TextFormatterComponent
            disabled={withoutTextFormatting}
            classNames={`
            animation-02s-all 
            ${isMobile ? 'mobile-h3-text' : 'desktop-h3-text'} 
            ${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'}
          `}
            text={currLang === Lang.RUS ? rusText : engText}
          />
        )}
      </div>
      {thirdChild}
    </div>
  );
}

export default MediaComponentWrapper