import {Link} from "react-router-dom";
import React from "react";
import StandardButton, {StandardButtonProps} from "./StandardButton";

interface StandardButtonWithLinkProps extends Omit<StandardButtonProps, "onClick"> {
  to: string;
  external?: boolean;
}

const StandardButtonWithLink: React.FC<StandardButtonWithLinkProps> = ({ to, external, ...menuItemProps}) => {
  return (
    <Link
      to={to}
      target={external ? '_blank' : undefined}
      style={{ textDecoration: "none" }}
    >
      <StandardButton
        {...menuItemProps}
        onClick={() => {}}
      />
    </Link>
  );
};

export default StandardButtonWithLink;