import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from "react-redux";
import reduxStore from "./redux/ReduxStore";
import './assets/fonts/InterDisplay-Regular.ttf'
import './assets/fonts/InterDisplay-SemiBold.ttf'
import './assets/fonts/InterDisplay-Bold.ttf'
import './assets/fonts/InterDisplay-Medium.ttf'
import { HelmetProvider } from 'react-helmet-async';
import { ParallaxProvider } from 'react-scroll-parallax';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <HelmetProvider>
    <ParallaxProvider>
      <Provider store={reduxStore}>
        <Router>
          <App />
        </Router>
      </Provider>
    </ParallaxProvider>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
