import React from "react";
import TextComponent, {
  TextComponentType
} from "../../ui/pages/project_detailed_page/info_components/text_component/TextComponent";
import MediaComponentWrapper, {
  MediaComponentStructure
} from "../../ui/pages/project_detailed_page/info_components/media_component/MediaComponentWrapper";
import ImageComponent
  from "../../ui/pages/project_detailed_page/info_components/media_component/image_component/ImageComponent";
import VimeoPlayer, {
  VideoPauseOnHideConfig
} from "../../ui/pages/project_detailed_page/info_components/media_component/vimeo_player/VimeoPlayer";
import Credits from "../../ui/pages/project_detailed_page/info_components/credits/Credits";

const VirtonexPage: React.FC = () => {
  return(
    <>
      <TextComponent
        type={TextComponentType.H3_LONG}
        rusText={'Насыщенная синяя цветовая гамма акцентных элементов с тёмным фоном создают «технологичное» сочетание.'}
        engText={'The rich blue color scheme of the accent pieces with the dark background create a «technological» palette.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/ba96r0N.jpg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'<p>Сервис, условно говоря, состоит из двух частей: это главная страница, где можно просматривать существующие пространства, созданные вами или другими пользователями, и конструктор для создания и редактирования собственных пространств.</p>Для того, чтоб работать с конструктором было удобно, экран был поделён на три части. В первой колонке название и основная информация, во второй — детальные настройки, в третьей — превью изображений и что‑то вспомогательное. Тут есть и ещё один секрет: в дальнейшем макет будет очень легко адаптировать под любые экраны.'}
        engText={'<p>The service, conventionally speaking, consists of two parts: the main page, where you can view existing spaces created by you or other users, and the constructor for creating and editing your own spaces.</p>To make it convenient to work with the constructor, the screen has been divided into three parts. There is the title and basic information in the first column, detailed settings in the second, in the third — previews of images and something auxiliary. There is one more secret here: in the future, the layout will be very easy to adapt to any screen size.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.FULL_WIDTH}>
        <VimeoPlayer videoId={'926637893'} pauseOnHideConfig={VideoPauseOnHideConfig.FIRST_SCROLL} loop={true} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'Используя градиенты и обводки, я немного отодвинул интерфейс от минимализма. Тем не менее, он остается «чистым».'}
        engText={'By using gradients and stroke, I’ve moved the interface away from minimalism a bit. Nevertheless, it remains «clean».'}
      />
      <TextComponent
        type={TextComponentType.H3_SHORT}
        rusText={'<p>Градиентная обводка превью фотографии случайным образом создала крутое сочетание со световыми элементами электромобиля. Визуал действительно отвечает Web3-тематике :)'}
        engText={'<p>The gradient outline of the preview photo randomly created a cool combination with the light elements of the electric car. The visuals really fit the Web3 theme :)'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/3Y2NQPB.jpg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'Множество поп-апов и контекстных меню детально продуманы, чтобы сократить пользовательский путь и сделать взаимодействие с интерфейсом приятным.'}
        engText={'Lots of pop-ups and context menus are detailed to shorten the user journey and make interacting with the interface enjoyable.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.FULL_WIDTH}>
        <ImageComponent
          src={'https://i.imgur.com/X8WeL0A.jpg'}
          withParallax={true}
          scale={1.15}
          parallaxStrength={0.06}
        />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.H3_LONG}
        rusText={'Для управления персоналом и гостями на мероприятиях, создаваемых на базе сервиса, используется функциональная таблица.'}
        engText={'A function table is used to manage staff and guests at service-generated events.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/ky0gSdE.jpg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/nHSNELv.jpg'} />
      </MediaComponentWrapper>
      <Credits lines={[
        { rusName: 'Product and UI/UX design:', rusVal: 'Глеб Коссов', engName: 'Product and UI/UX design:', engVal: 'Gleb Kossov' },
        { engName: 'Licensed mockups:', engVal: 'LS graphics', link: 'https://www.ls.graphics/' }
      ]}/>
    </>
  )
}

export default VirtonexPage