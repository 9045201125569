import './MenuItem.css'
import './../../../assets/styles/typography.css'
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../redux/ReduxStore";
import {ColorTheme} from "../../../constants/ColorTheme";
import useHoverAndClick from "../../../utils/hooks/UseHoverAndClickHook";

export interface MenuItemProps {
  onClick: () => void;
  text: string;
  isActive: boolean;
  cursor?: any;
}

const MenuItem: React.FC<MenuItemProps> = ({ onClick, text, isActive, cursor }) => {
  const {isHovered, isClicked, ...eventHandlers} = useHoverAndClick()
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)

  useEffect(() => {
    console.log(cursor)
  }, []);

  return(
    <div
      className={`
        animation-02s-all 
        menu-item-wrapper 
        ${currTheme === ColorTheme.WHITE 
          ? isClicked ? 'clicked' : isHovered && 'hovered' 
          : isClicked ? 'clicked-dark' : isHovered && 'hovered-dark'
        }
        menu-item-text 
        ${currTheme === ColorTheme.WHITE 
          ? isActive || isHovered ? 'text-primary-text' : 'text-gray-main-text' 
          : isActive ? 'text-primary-text-dark' : 'text-gray-main-text-dark'
        }
      `}
      style={{ cursor: cursor ? `url(${cursor}), auto` : isHovered ? 'pointer' : 'inherit' }}
      onClick={onClick}
      {...eventHandlers}
    >
      {text}
    </div>
  )
}

export default MenuItem