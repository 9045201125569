import './MaterialsPage.css'
import React from "react";
import {MediaQueries} from "../../../constants/MediaQueries";
import {useMediaQuery} from "react-responsive";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../redux/ReduxStore";
import {ColorTheme} from "../../../constants/ColorTheme";
import {Lang} from "../../../constants/Lang";
import {RoutePaths} from "../../../constants/RoutePaths";
import StandardButtonWithLink from "../../shared_components/standard_button/StandardButtonWithLink";

const MaterialsPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })
  const currLang = useSelector((state: RootStoreState) => state.lang)
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)

  return(
    <div className={`materials-page-wrapper ${!isMobile && 'desktop'}`}>
      <div className={'materials-text-wrapper'}>
        <h1 className={`animation-02s-all ${isMobile ? 'mobile-h1-text' : 'desktop-h1-text'} ${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'}`}>
          {currLang === Lang.ENG ? 'Soon there will be some useful design materials here' : 'Скоро здесь будут полезные материалы для дизайна'}
        </h1>
        <div className={`animation-02s-all ${isMobile ? 'mobile-main-text' : 'desktop-main-text'} ${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'}`}>
          {currLang === Lang.ENG ? 'We strive to\u00A0make the section really useful, so\u00A0you\'ll need to wait a\u00A0little bit' : 'Мы стремимся сделать раздел действительно полезным, поэтому нужно немного подождать'}
        </div>
        <div style={{ marginTop: 24 }}>
          <StandardButtonWithLink
            to={RoutePaths.HOME}
            text={currLang === Lang.RUS ? 'Вернуться на главную' : 'Return to home page'}
          />
        </div>
      </div>
    </div>
  )
}

export default MaterialsPage