import './ShortInfoBlock.css'
import React from "react";
import LinkButton, {LinkButtonType} from "../../../shared_components/link_button/LinkButton";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/MediaQueries";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../../redux/ReduxStore";
import {Lang} from "../../../../constants/Lang";
import {ColorTheme} from "../../../../constants/ColorTheme";
import TextFormatterComponent from "../../../../utils/formatters/TextFormatterComponent";

export interface ShortInfoBlockProps {
  buttons: { type: LinkButtonType, link: string }[];
  engDescription: string;
  rusDescription: string;
}

const ShortInfoBlock: React.FC<ShortInfoBlockProps> = ({ buttons, engDescription, rusDescription}) => {
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })
  const isDesktop = useMediaQuery({ query: MediaQueries.DESKTOP })
  const isTablet = useMediaQuery({ query: MediaQueries.TABLET })
  const currLang = useSelector((state: RootStoreState) => state.lang)
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)

  return(
    <div className={'short-info-block-wrapper-absolute'}>
      <div className={`short-info-block-wrapper ${isMobile && 'mobile'}`}>
        <h3
          className={`animation-02s-all ${isMobile ? 'mobile-h3-text' : 'desktop-h3-text'} ${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'}`}
          style={{ marginBottom: 12, minWidth: isDesktop ? 340 : isTablet ? 300 : undefined}}
        >
          {currLang === Lang.ENG ? 'About the project' : 'О проекте'}
        </h3>
        <div className={`text-and-link-btns ${isMobile && 'mobile'}`}>
          <TextFormatterComponent
            text={currLang === Lang.ENG ? engDescription : rusDescription}
            classNames={`animation-02s-all ${isMobile ? 'mobile-accent-text' : 'desktop-accent-text'} ${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'} short-descr`}
          />
          {buttons?.length > 0 && (
            <div className={`link-btns-wrapper ${isMobile && 'mobile'}`}>
              {
                buttons.map(b =>
                  <LinkButton
                    key={`b-link-${b?.type}`}
                    link={b?.link}
                    type={b?.type}
                  />
                )
              }
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ShortInfoBlock