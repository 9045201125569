import './CreditLink.css'
import React from "react";
import {ColorTheme} from "../../../../../../constants/ColorTheme";
import {Lang} from "../../../../../../constants/Lang";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../../../constants/MediaQueries";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../../../../redux/ReduxStore";
import useHover from "../../../../../../utils/hooks/UseHoverHook";

interface CreditLinkProps {
  link: string | undefined;
  rusText?: string;
  engText: string;
}

const CreditLink: React.FC<CreditLinkProps> = ({ link, rusText, engText }) => {
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)
  const currLang = useSelector((state: RootStoreState) => state.lang)
  const {isHovered, ...hoverHandlers} = useHover()

  return(
    <a
      rel="noreferrer"
      href={link}
      target={'_blank'}
      className={`
              animation-02s-all ${link && 'underlined'} 
              ${isHovered && link && (currTheme === ColorTheme.WHITE ? 'hovered-white' : 'hovered-dark')}
              ${currTheme === ColorTheme.WHITE ? 'text-primary-text' : 'text-primary-text-dark'}
              ${!isMobile ? 'desktop-accent-text' : 'mobile-main-text'}`
      }
      {...hoverHandlers}
    >
      {currLang === Lang.RUS && rusText ? rusText : engText}
    </a>
  )
}

export default CreditLink