import './Credits.css'
import React from "react";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../../constants/MediaQueries";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../../../redux/ReduxStore";
import {ColorTheme} from "../../../../../constants/ColorTheme";
import {Lang} from "../../../../../constants/Lang";
import CreditLink from "./credit_link/CreditLink";

export interface CreditsProps {
  lines: {
    engName: string,
    engVal: string,
    rusName?: string,
    rusVal?: string,
    link?:string
  }[]
}

const Credits: React.FC<CreditsProps> = ({ lines}) => {
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)
  const currLang = useSelector((state: RootStoreState) => state.lang)

  return(
    <div className={`credits-wrapper ${isMobile ? 'mobile' : 'desktop'}`}>
      {lines?.map(l => (
        <div className={'credit-line-wrapper'} key={`cred-line-${l?.engName}`}>
          <div
            className={`
              animation-02s-all 
              ${currTheme === ColorTheme.WHITE ? 'text-gray-main-text' : 'text-gray-main-text-dark'}
              ${!isMobile ? 'desktop-accent-text' : 'mobile-main-text'}`
            }
            style={{ marginRight: 6 }}
          >
            {currLang === Lang.RUS && l?.rusName ? l?.rusName : l?.engName}
          </div>
          <CreditLink link={l?.link} engText={l?.engVal} rusText={l?.rusVal}/>
        </div>
      ))}
    </div>
  )
}

export default Credits