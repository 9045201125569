import './TextComponent.css'
import React from "react";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../../constants/MediaQueries";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../../../redux/ReduxStore";
import {Lang} from "../../../../../constants/Lang";
import {ColorTheme} from "../../../../../constants/ColorTheme";
import TextFormatterComponent from "../../../../../utils/formatters/TextFormatterComponent";

export enum TextComponentType {
  H3_LONG,
  H3_SHORT,
  ACCENT_TEXT_SHORT,
  ACCENT_TEXT_LONG
}

interface TextComponentProps {
  type: TextComponentType;
  rusText: string;
  engText: string;
}

const TextComponent: React.FC<TextComponentProps> = ({ type, rusText, engText }) => {
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })
  const currLang = useSelector((state: RootStoreState) => state.lang)
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)

  return(
    <div
      className={`
        text-component animation-02s-all 
        ${isMobile && 'mobile'}
      `}
    >
      <TextFormatterComponent
        classNames={`
          animation-02s-all 
          ${(type === TextComponentType.ACCENT_TEXT_SHORT || type === TextComponentType.H3_SHORT) && !isMobile && 'shorten'}
          ${isMobile
            ? (type === TextComponentType.ACCENT_TEXT_LONG || type === TextComponentType.ACCENT_TEXT_SHORT) 
              ? 'mobile-accent-text' 
              : 'mobile-h3-text'
            : (type === TextComponentType.ACCENT_TEXT_LONG || type === TextComponentType.ACCENT_TEXT_SHORT) 
              ? 'desktop-accent-text' 
              : 'desktop-h3-text'
          } 
          ${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'}
        `}
        text={currLang === Lang.RUS ? rusText : engText}
      />
    </div>
  )
}

export default TextComponent