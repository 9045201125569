import './AboutPage.css'
import React from "react";
import {MediaQueries} from "../../../constants/MediaQueries";
import {useMediaQuery} from "react-responsive";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../redux/ReduxStore";
import {ColorTheme} from "../../../constants/ColorTheme";
import {Lang} from "../../../constants/Lang";
import TextFormatterComponent from "../../../utils/formatters/TextFormatterComponent";
const avatar = 'https://i.imgur.com/Br27mQQ.jpg'

const AboutPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })
  const isDesktop = useMediaQuery({ query: MediaQueries.DESKTOP })
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)
  const currLang = useSelector((state: RootStoreState) => state.lang)

  return(
    <div className={`about-page-wrapper ${isDesktop && 'desktop'}`}>
      <div className={`about-text-wrapper ${isDesktop && 'desktop'}`}>
        <h1 className={`${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'} ${isMobile ? 'mobile-h1-text' : 'desktop-h1-text'}`}>
          {currLang === Lang.RUS ? 'Привет! Меня зовут Глеб.' : 'Hi! My name is Gleb.'}
        </h1>
        <div className={'about-main-text-wrapper'}>
          <TextFormatterComponent
            text={currLang === Lang.ENG
              ? 'I create clear and simple design solutions to solve complex problems in non-trivial ways.'
              : 'Я создаю понятные и простые решения в дизайне, чтобы решить сложные проблемы не всегда очевидными путями.\n'
            }
            classNames={`${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'} ${isMobile ? 'mobile-main-text' : 'desktop-accent-text'}`}
          />
          <TextFormatterComponent
            text={currLang === Lang.ENG
              ? 'Using my experience in marketing as well as communication with people and technology, with each new project I strive to reach the thin border between user experience and visual perception of the product.'
              : 'Используя опыт в маркетинге, общении с людьми и техникой, я с каждым новым проектом стремлюсь приблизиться к тонкой границе между опытом пользователя и удовольствием от визуального восприятия продукта.'
            }
            classNames={`${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'} ${isMobile ? 'mobile-main-text' : 'desktop-accent-text'}`}
          />
          <TextFormatterComponent
            text={currLang === Lang.ENG
              ? 'And also it is very important for me to find a thought-through, neat and efficient design in everything surrounding us. I believe that the functional qualities of a bus stop, an information board, a subway, and an office entrance depend on design solutions no less than on engineering ones. It defines my passion for perfect design — technically and substantially.'
              : 'А ещё для меня очень важно видеть продуманный, аккуратный и эффективный дизайн во всем, что нас окружает. Я верю в то, что автобусная остановка, информационное табло, метро, входная группа офиса в своих функциональных качествах зависят от дизайнерских решений не меньше, чем от инженерных. В этом причина моей страсти к идеальному и технически, и содержательно дизайну.'
            }
            classNames={`${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'} ${isMobile ? 'mobile-main-text' : 'desktop-accent-text'}`}
          />
        </div>
      </div>
      <img alt={'avatar'} src={avatar} className={isMobile ? 'avatar-mobile' : 'avatar-desktop'}/>
    </div>
  )
}

export default AboutPage
