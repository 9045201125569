import React, {useMemo} from "react";
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";
import {RoutePaths} from "../../constants/RoutePaths";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../redux/ReduxStore";
import {Lang} from "../../constants/Lang";

const MetaTagsManager: React.FC = () => {
  const location = useLocation()
  const currLang = useSelector((state: RootStoreState) => state.lang)

  const metaTags = useMemo<{ title: string, description: string }>(() => {
    switch (location?.pathname) {
      case RoutePaths.ABOUT:
        return currLang === Lang.RUS
          ? { title: 'Обо мне — Глеб Коссов', description: 'Обо мне — Глеб Коссов' }
          : { title: 'About — Gleb Kossov', description: 'About — Gleb Kossov' }
      case RoutePaths.ARCHIVE:
        return currLang === Lang.RUS
          ? { title: 'Архив — Глеб Коссов', description: 'Архив — Глеб Коссов' }
          : { title: 'Archive — Gleb Kossov', description: 'Archive — Gleb Kossov' }
      case RoutePaths.MATERIALS:
        return currLang === Lang.RUS
          ? { title: 'Дизайн-материалы — Глеб Коссов', description: 'Дизайн-материалы — Глеб Коссов' }
          : { title: 'Design materials — Gleb Kossov', description: 'Design materials — Gleb Kossov' }
    }

    return currLang === Lang.RUS
      ? { title: 'Глеб Коссов — дизайн-портфолио', description: 'Глеб Коссов — дизайн-портфолио' }
      : { title: 'Gleb Kossov — design portfolio', description: 'Gleb Kossov — design portfolio' }
  }, [location?.pathname, currLang]);

  return(
    <Helmet>
      <html lang={currLang === Lang.RUS ? 'ru' : 'en'} />
      <title>{metaTags?.title}</title>
      <meta name="description" content={metaTags?.description} data-rh={true}/>
    </Helmet>
  )
}

export default MetaTagsManager