import './Header.css'
import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";
import {RootStoreState} from "../../../redux/ReduxStore";
import {MediaQueries} from "../../../constants/MediaQueries";
import {ColorTheme} from "../../../constants/ColorTheme";
import BurgerButton from "../burger/burger_button/BurgerButton";
import Logo from "./logo/Logo";
import MenuItemsBlock from "../menu_items_block/MenuItemsBlock";
import ColorThemeSwitcher from "../color_theme_switcher/colorThemeSwitcher";
import MenuItem from "../menu_item/MenuItem";
import {toggleLang} from "../../../redux/LangReducer";
import {Lang} from "../../../constants/Lang";
import {useLocation} from "react-router-dom";
import {animated, useScroll} from 'react-spring'
import {
  checkIfDetailedProjectExists,
  checkIfPathBelongsToProjectDetailedPage,
  extractProjectIdFromPath
} from "../../../utils/project_actions/ProjectActions";
import {ProjectData} from "../../../project_data/ProjectData";
import useWindowParams from "../../../utils/hooks/UseWindowParamsHook";

const Header: React.FC = () => {
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)
  const currLang = useSelector((state: RootStoreState) => state.lang)

  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })
  const isTablet = useMediaQuery({ query: MediaQueries.TABLET })
  const isDesktop = useMediaQuery({ query: MediaQueries.DESKTOP })

  const dispatch = useDispatch()
  const location = useLocation()

  const isValidProductPage = useMemo(() => {
    return checkIfPathBelongsToProjectDetailedPage(location?.pathname)
      && checkIfDetailedProjectExists(extractProjectIdFromPath(location?.pathname), ProjectData)
  }, [location])

  const { scrollY } = useScroll({ config: { tension: 0, friction: 0 }})
  const { width} = useWindowParams()

  return(
    <div className="header-wrapper">
      {!isDesktop && isValidProductPage && (
        <animated.div
          className={`
          header
          ${isMobile ? 'mobile':  'tablet'} 
          ${currTheme === ColorTheme.DARK ? 'dark' : 'white'}
          ${isValidProductPage && (isDesktop) && 'abs'}
        `}
          style={{
            opacity: scrollY.to(s => {
              if (isMobile) {
                if (s < width * 2 / 3) {
                  return 0
                }
                return (s - width * 2 / 3 + 50) / (width * 2 / 4)
              } else {
                if (s < width * 54 / 144) {
                  return 0
                }
                return (s - width * 54 / 144 + 50) / (width * 54 / 216)
              }
            })
          }}
        />
      )}
        <div
        className={`
          header animation-02s-all
          ${isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'} 
          ${currTheme === ColorTheme.DARK ? 'dark' : 'white'}
          ${isValidProductPage && 'transparent'}
          ${isValidProductPage && (isDesktop) && 'abs'}
        `}
      >
        <Logo/>
        {isDesktop ? (
          <>
            <MenuItemsBlock containerStyle={{ flexDirection: "row", gap: 16, alignItems: 'center' }} onlyRoutes={true} />
            <div className={'desktop-selectors-wrapper'}>
              <ColorThemeSwitcher/>
              <MenuItem
                onClick={() => dispatch(toggleLang())}
                text={currLang === Lang.ENG ? 'EN' : 'RU'}
                isActive={true}
              />
            </div>
          </>
        ) : (
          <BurgerButton/>
        )}
      </div>
    </div>
  )
}

export default Header
