import './colorThemeSwitcher.css'
import '../../../assets/styles/animation_durations.css'
import React, {useEffect, useState} from "react";
import {ColorTheme} from "../../../constants/ColorTheme";
import {useDispatch, useSelector} from "react-redux";
import SunRotator from "./sun_rotator/SunRotator";
import {RootStoreState} from "../../../redux/ReduxStore";
import {toggleTheme} from "../../../redux/ColorThemeReducer";
import Boop from "../boop/Boop";
import useHover from "../../../utils/hooks/UseHoverHook";

const ColorThemeSwitcher: React.FC = () => {
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)
  const dispatch = useDispatch()

  const [isBooped, setIsBooped] = useState(false)
  const [isRotated, setRotated] = useState(false)
  const {isHovered, ...hoverHandlers} = useHover()

  useEffect(() => {
    if (isBooped) {
      setTimeout(() => {
        setIsBooped(false)
      }, 200)
    }
  }, [isBooped]);

  useEffect(() => {
    if (isRotated) {
      setTimeout(() => {
        setRotated(false)
      }, 200)
    }
  }, [isRotated]);

  return(
    <div
      className={`
        color-theme-switcher-wrapper 
        animation-02s-all
      `}
      onClick={() => {
        setIsBooped(true)
        setRotated(true)
        dispatch(toggleTheme())
      }}
      {...hoverHandlers}
    >
      {currTheme === ColorTheme.DARK ? (
        <SunRotator trigger={isRotated}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle className={'animation-02s-all'} opacity="0.0" cx="12" cy="12" r="5" fill={isHovered ? '#FFD749' : 'transparent'}/>
            <path className={'animation-02s-all'} d="M12 20L12 22M12 2L12 4M20 12H22M2 12H4M18 17.9995L19.5 19.4995M4.5 4.49949L6.00002 5.99953M18 6L19.5 4.5M4.5 19.5L6 18M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z" stroke={isHovered ? '#FFD749' : '#ACACAC'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </SunRotator>
      ) : (
        <Boop tension={400} friction={5} rotation={20} externalTrigger={isBooped}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={'animation-02s-all'} opacity="0.2" d="M3 11.4489C3 16.7238 7.16904 21 12.3118 21C16.2709 21 19.6529 18.4657 21 14.8925C19.9331 15.4065 18.7418 15.6938 17.485 15.6938C12.9137 15.6938 9.20787 11.8928 9.20787 7.20396C9.20787 5.24299 9.85605 3.4373 10.9446 2C6.45002 2.6783 3 6.65034 3 11.4489Z" fill={isHovered ? '#FFD749' : 'transparent'}/>
            <path className={'animation-02s-all'} d="M3 11.4489C3 16.7238 7.16904 21 12.3118 21C16.2709 21 19.6529 18.4657 21 14.8925C19.9331 15.4065 18.7418 15.6938 17.485 15.6938C12.9137 15.6938 9.20787 11.8928 9.20787 7.20396C9.20787 5.24299 9.85605 3.4373 10.9446 2C6.45002 2.6783 3 6.65034 3 11.4489Z" stroke={isHovered ? '#FFD749' : 'black'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </Boop>
      )}
    </div>
  )
}

export default ColorThemeSwitcher
