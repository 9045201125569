import './LinkButton.css'
import './../../../assets/styles/typography.css'
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../redux/ReduxStore";
import {ColorTheme} from "../../../constants/ColorTheme";
import useHoverAndClick from "../../../utils/hooks/UseHoverAndClickHook";
import {Lang} from "../../../constants/Lang";
import {Link} from "react-router-dom";

export enum LinkButtonType {
  GOOGLE_PLAY,
  APP_STORE,
  BEHANCE,
  WEBSITE,
}

export interface LinkButtonProps {
  link: string;
  type: LinkButtonType;
}

const LinkButton: React.FC<LinkButtonProps> = ({ link, type }) => {
  const {isHovered, isClicked, ...eventHandlers} = useHoverAndClick()
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)
  const currLang = useSelector((state: RootStoreState) => state.lang)

  const resolvedText = useMemo(() => {
    switch (type) {
      case LinkButtonType.APP_STORE:
        return currLang === Lang.ENG ? 'Open on App Store' : 'Приложение в App Store'
      case LinkButtonType.BEHANCE:
        return currLang === Lang.ENG ? 'Open on Behance' : 'Кейс на Бихансе'
      case LinkButtonType.GOOGLE_PLAY:
        return currLang === Lang.ENG ? 'Open on Google Play' : 'Приложение в Google Play'
      case LinkButtonType.WEBSITE:
        return currLang === Lang.ENG ? 'Open website' : 'Открыть сайт'
    }
  }, [type, currLang]);

  const resolvedIcon = useMemo(() => {
    switch (type) {
      case LinkButtonType.BEHANCE:
        return(
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_175_2472)">
              <path
                className={'animation-02s-all'}
                d="M22 7H15V5H22V7ZM23.726 17C23.284 18.297 21.697 20 18.625 20C15.551 20 13.061 18.271 13.061 14.325C13.061 10.415 15.386 8.405 18.527 8.405C21.609 8.405 23.491 10.187 23.902 12.831C23.98 13.337 24.011 14.019 23.997 14.971H15.97C16.1 18.182 19.453 18.283 20.558 17H23.726ZM16.04 13H21.005C20.9 11.453 19.869 10.781 18.528 10.781C17.062 10.781 16.251 11.549 16.04 13ZM6.466 19.988H0V5.021H6.953C12.429 5.102 12.533 10.465 9.673 11.927C13.134 13.187 13.25 19.988 6.466 19.988ZM3 11H6.584C9.092 11 9.49 8 6.272 8H3V11ZM6.391 14H3V17.016H6.341C9.396 17.016 9.209 14 6.391 14Z"
                fill={isHovered || isClicked || currTheme === ColorTheme.DARK ? 'white' : 'black'}
              />
            </g>
            <defs>
              <clipPath id="clip0_175_2472">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        )
      case LinkButtonType.WEBSITE:
        return(
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_195_1204)">
              <path
                className={'animation-02s-all'}
                d="M23 12C23 18.0751 18.0751 23 12 23M23 12C23 5.92487 18.0751 1 12 1M23 12C23 9.56995 18.0751 7.6 12 7.6C5.92487 7.6 1 9.56995 1 12M23 12C23 14.4301 18.0751 16.4 12 16.4C5.92487 16.4 1 14.4301 1 12M12 23C5.92487 23 1 18.0751 1 12M12 23C14.4301 23 16.4 18.0751 16.4 12C16.4 5.92487 14.4301 1 12 1M12 23C9.56995 23 7.6 18.0751 7.6 12C7.6 5.92487 9.56995 1 12 1M1 12C1 5.92487 5.92487 1 12 1"
                stroke={currTheme === ColorTheme.DARK ? 'white' : 'black'}
                strokeWidth="1.65"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_195_1204">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        )
      case LinkButtonType.APP_STORE:
        return(
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_194_1145)">
              <path
                className={'animation-02s-all'}
                d="M11.9954 5.46271L12.4359 4.70323C12.7068 4.22864 13.3115 4.06916 13.7861 4.34013C14.2606 4.6111 14.4201 5.21574 14.1492 5.69032L9.9151 13.0212H12.9778C13.9695 13.0212 14.5262 14.1871 14.0957 14.9954H5.11974C4.57316 14.9954 4.13265 14.5548 4.13265 14.0083C4.13265 13.4617 4.57316 13.0212 5.11974 13.0212H7.63587L10.8581 7.4369L9.85161 5.69032C9.58065 5.21574 9.74013 4.61574 10.2147 4.34013C10.6885 4.06916 11.2885 4.22864 11.5649 4.70323L11.9954 5.46271ZM8.1871 16.0111L7.23871 17.6563C6.96774 18.1308 6.3631 18.2903 5.88852 18.0194C5.41394 17.7484 5.25445 17.1437 5.52542 16.6692L6.23226 15.4498C7.02581 15.2028 7.67458 15.3917 8.1871 16.0111ZM16.3595 13.0258H18.929C19.4756 13.0258 19.9161 13.4663 19.9161 14.0129C19.9161 14.5595 19.4756 15 18.929 15H17.5014L18.4645 16.6692C18.7355 17.1437 18.5752 17.743 18.1014 18.0194C17.6268 18.2903 17.0268 18.1308 16.7512 17.6563C15.1301 14.8452 13.9107 12.7401 13.1032 11.3373C12.2756 9.90968 12.8663 8.47742 13.4516 7.99355C14.0996 9.10684 15.0674 10.7853 16.3595 13.0258ZM12 0C5.37058 0 0 5.37135 0 12C0 18.6286 5.37135 24 12 24C18.6294 24 24 18.6286 24 12C24 5.37135 18.6286 0 12 0ZM22.4516 12C22.4516 17.7437 17.8018 22.4516 12 22.4516C6.25626 22.4516 1.54839 17.8018 1.54839 12C1.54839 6.25626 6.19819 1.54839 12 1.54839C17.7437 1.54839 22.4516 6.19819 22.4516 12Z"
                fill={isHovered || isClicked || currTheme === ColorTheme.DARK ? 'white' : 'black'}
              />
            </g>
            <defs>
              <clipPath id="clip0_194_1145">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        )
      case LinkButtonType.GOOGLE_PLAY:
        return(
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              className={'animation-02s-all'}
              d="M11.4999 13.5061L14.9378 16.944L3.625 23.4754C3.01097 23.83 2.31614 23.7647 1.79565 23.4268L1.6693 23.3367L11.4999 13.5061ZM1 3.00621L9.84998 11.8562L1 20.7062V3.00621ZM17.0298 7.97631L21.125 10.3407C22.2917 11.0143 22.2917 12.6982 21.125 13.3717L17.0298 15.7361L13.1499 11.8562L17.0298 7.97631ZM3.625 0.237065L14.9377 6.76848L11.4999 10.2063L1.66933 0.375712C2.19979 -0.0383383 2.9598 -0.14699 3.625 0.237065Z"
              fill={isHovered || isClicked || currTheme === ColorTheme.DARK ? 'white' : 'black'}
            />
          </svg>
        )
    }
  }, [type, isHovered, isClicked, currTheme]);

  const resolvedClasses = useMemo(() => {
    let classes = currTheme === ColorTheme.DARK
      ? 'link-btn link-btn-dark text-primary-text-dark text-desktop-btns '
      : 'link-btn link-btn-light text-primary-text text-desktop-btns '
    switch (type) {
      case LinkButtonType.BEHANCE:
        if (isClicked) {
          classes += 'clicked-behance scaled white-text'
        } else if (isHovered) {
          classes += 'hovered-behance scaled white-text'
        }
        break
      case LinkButtonType.GOOGLE_PLAY:
        if (isClicked) {
          classes += 'clicked-gp scaled white-text'
        } else if (isHovered) {
          classes += 'hovered-gp scaled white-text'
        }
        break
      case LinkButtonType.APP_STORE:
        if (isClicked) {
          classes += 'clicked-appstore scaled white-text'
        } else if (isHovered) {
          classes += 'hovered-appstore scaled white-text'
        }
        break
      case LinkButtonType.WEBSITE:
        if (isClicked) {
          classes += `scaled ${currTheme === ColorTheme.WHITE ? 'clicked-web-light' : 'clicked-web-dark'}`
        } else if (isHovered) {
          classes += `scaled ${currTheme === ColorTheme.WHITE ? 'hovered-web-light' : 'hovered-web-dark'}`
        }
        break
    }

    return classes
  }, [type, isHovered, isClicked, currTheme]);

  return(
    <div style={{ display: 'flex'}}>
      <Link
        style={{ textDecoration: 'none'}}
        target={'_blank'}
        to={link}
        className={`animation-02s-all ${resolvedClasses}`}
        {...eventHandlers}
      >
        {resolvedIcon}
        {resolvedText}
      </Link>
    </div>
  )
}

export default LinkButton