import './ImageComponent.css'
import React, {useMemo, useRef} from "react";
import {MouseParallax} from 'react-just-parallax';

export interface ImageComponentProps {
  src: string;
  backgroundFill?: string;
  withParallax?: boolean;
  parallaxStrength?: number;
  scale?: number;
  rotation?: number;
}

const ImageComponent: React.FC<ImageComponentProps> = ({
  src,
  withParallax,
  backgroundFill,
  scale,
  rotation,
  parallaxStrength
}) => {
  const parallaxContainerRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const img = useMemo(() => (
    <img
      src={src} alt={'img'}
      style={{
        width: '100%',
        height: 'auto',
        scale: scale ? `${100 * scale}%` : undefined,
        transform: rotation ? `rotate(${rotation}deg)` : undefined
      }}
      ref={withParallax ? imageRef : undefined}
    />
  ), [src, withParallax])

  return(withParallax ? (
      <div
        ref={parallaxContainerRef}
        className={'parallax-image-wrapper'}
        style={{
          backgroundColor: backgroundFill ? backgroundFill : undefined,
          maxHeight: withParallax ? imageRef?.current?.height || 0 - 3 : undefined
        }}
      >
        <MouseParallax
          shouldResetPosition={true}
          strength={parallaxStrength ? parallaxStrength : 0.04}
          lerpEase={0.03}
          parallaxContainerRef={parallaxContainerRef}
        >
          {img}
        </MouseParallax>
      </div>
    ) : (
      img
    ))
}

export default ImageComponent