import './ParallaxHeadBanner.css'
import ToolTag, {ToolTagSize, ToolTagType} from "../../../shared_components/tool_tag/ToolTag";
import React from "react";
import {ParallaxBanner} from "react-scroll-parallax";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/MediaQueries";

export interface ParallaxHeadBannerProps {
  tags: ToolTagType[];
  image: any;
  name: string;
}

const ParallaxHeadBanner: React.FC<ParallaxHeadBannerProps> = ({ tags, name, image}) => {
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })

  return(
    <ParallaxBanner
      layers={[
        {image: image, speed: -20, style: { bottom: -15, backgroundSize: 'cover' }},
        {
          speed: 0,
          children: (
            <div className={`parallax-wrapper ${!isMobile && 'desktop'}`}>
              <div className={`tools-and-name-wrapper ${!isMobile && 'desktop'}`} >
                <div className={'tools-wrapper'}>
                  {tags?.map(t =>
                    <ToolTag type={t} size={isMobile ? ToolTagSize.MOBILE : ToolTagSize.DESKTOP} key={`tag-${t}`}/>
                  )}
                </div>
                <h1 className={`${isMobile ? 'mobile-h1-text' : 'desktop-h1-text'} text-primary-text-dark`}>
                  {name}
                </h1>
              </div>
            </div>
          ),
        },
      ]}
      className={`parallax-all-wrapper ${!isMobile && 'desktop'}`}
    />
  )
}

export default ParallaxHeadBanner