import './Footer.css'
import './../../../assets/styles/animation_durations.css'
import React from "react";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../constants/MediaQueries";
import MenuItemsBlock from "../menu_items_block/MenuItemsBlock";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../redux/ReduxStore";
import {Lang} from "../../../constants/Lang";
import {ColorTheme} from "../../../constants/ColorTheme";
import CopyrightLink from "./copyright_link/CopyrightLink";

const Footer: React.FC = () => {
  const isDesktop = useMediaQuery({ query: MediaQueries.DESKTOP })

  const currLang = useSelector((state: RootStoreState) => state.lang)
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)

  return(
    <div className={`animation-02s-all footer-wrapper ${currTheme === ColorTheme.DARK && 'dark'} ${currTheme === ColorTheme.DARK ? 'text-gray-main-text-dark' : 'text-gray-main-text'}`}>
      <MenuItemsBlock
        containerStyle={{
          flexDirection: isDesktop ? 'row' : 'column',
          alignItems: 'center',
          gap: 16
        }}
      />
      <div className={'copyright-container-wrapper'}>
        <div>
          © {new Date().getFullYear()} {currLang === Lang.ENG ? 'Gleb Kossov' : 'Глеб Коссов'}
        </div>
        <CopyrightLink/>
      </div>
    </div>
  )
}

export default Footer