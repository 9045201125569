import React from "react";
import MediaComponentWrapper, {
  MediaComponentStructure
} from "../../ui/pages/project_detailed_page/info_components/media_component/MediaComponentWrapper";
import ImageComponent
  from "../../ui/pages/project_detailed_page/info_components/media_component/image_component/ImageComponent";
import TextComponent, {
  TextComponentType
} from "../../ui/pages/project_detailed_page/info_components/text_component/TextComponent";
import Credits from "../../ui/pages/project_detailed_page/info_components/credits/Credits";
import VimeoPlayer, {
  VideoPauseOnHideConfig
} from "../../ui/pages/project_detailed_page/info_components/media_component/vimeo_player/VimeoPlayer";

const YourmealPage: React.FC = () => {
  return(
    <>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/oaIdBhV.jpg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'Создавая Yourmeal, я, во‑первых, придерживался отработанных паттернов. Насыщенный зелёный цвет, округлённый «дружелюбный» дизайн интерфейса, мобильная версия в адаптиве задизайнена под приложение — всё в стиле крутого технологичного продукта.'}
        engText={'When creating Yourmeal, I, first of all, adhered to well-established patterns. Rich green color, rounded «friendly» interface design, mobile version in adaptive design looking like mobile app — all in the style of a cool technological product.'}
      />
      <MediaComponentWrapper
        withoutTextFormatting={true}
        structure={MediaComponentStructure.WITH_TEXT}
        engText={'Apart from the brand green, no accent colors are used, thus maximizing visual space for expressive and eye-catching food photography.'}
        rusText={'Кроме фирменного зелёного никакие акцентные цвета не используются, таким образом, максимально освобождается визуальное пространство для выразительных и привлекательных фотографий еды.'}
      >
        <VimeoPlayer videoId={'928651668'} loop={false} pauseOnHideConfig={VideoPauseOnHideConfig.FIRST_SCROLL} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'Интерфейс мобильной версии создан отдельно. Чтобы веб-версией можно было пользоваться с таким же удовольствием, как нативным приложением, были продуманы крупные радиокнопки, чекбоксы и всплывающие модальные окна, выработаны зоны нажатия и отступы. Шапка в мобильной версии выполняет роль навигационной панели. Навигация по разделам осуществялется с помощью таб-бара.'}
        engText={'The interface of the mobile version was created separately. Large radio buttons, checkboxes and pop-up modal windows were designed, click zones and indents were worked out so that the web version could be used with the same pleasure as the native application. The header in the mobile version serves as a navigation bar. Navigation through the sections is performed using the tap bar.'}
      />
      <MediaComponentWrapper
        structure={MediaComponentStructure.DOUBLE_MEDIA}
        thirdChild={<ImageComponent src={'https://i.imgur.com/hFZbXGT.jpg'} />}
      >
        <ImageComponent src={'https://i.imgur.com/w1ko5RI.jpg'}/>
        <ImageComponent src={'https://i.imgur.com/t8BUZWW.jpg'}/>
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'Менее приоритетная информация скрывается на мобильных устройствах, так, чтобы пользователю не приходилось заниматься чрезмерным скроллингом.'}
        engText={'Less prioritized information is hidden on mobile devices, so that the user doesn’t have to do excessive scrolling.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/Hv4ukM4.jpg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'Пользовательский путь предполагает применение всплывающих окон там, где это возможно. Таким образом он становится понятнее, доступнее для восприятия.'}
        engText={'The user journey involves the use of pop-ups wherever possible. In this way it becomes clearer, easier to understand.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/RwPU4i0.jpg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'На этапе MVP было отрисовано около 100 экранов суммарно для мобильной и десктопной версии.'}
        engText={'At the MVP stage, about 100 screens were rendered in total for the mobile and desktop versions.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.FULL_WIDTH}>
        <ImageComponent withParallax={true} src={'https://i.imgur.com/wgZmkMD.jpg'} scale={1.15}/>
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.H3_SHORT}
        rusText={'Для владельцев заведений разработан отдельный модуль CMS, который позволяет принимать заказы, отслеживать их обработку и работать с контентом.'}
        engText={'A separate CMS module has been developed for owners of restaurants, which allows them to accept orders, track their processing and work with content.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/Baf2BDD.jpg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper
        withoutTextFormatting={true}
        structure={MediaComponentStructure.WITH_TEXT}
        engText={'Even though the CMS is mostly used from large screens, a mobile version was made without any loss in functionality.'}
        rusText={'Несмотря на то, что CMS в основном используется с больших экранов, была сделана мобильная версия без потерь в функциональности.'}
      >
        <VimeoPlayer videoId={'928651563'} loop={true} pauseOnHideConfig={VideoPauseOnHideConfig.FIRST_SCROLL} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'Систему легко использовать и с планшетных компьютеров. Предполагется, что официанты могут использовать планшеты, чтобы отслеживать и редактировать заказы.'}
        engText={'The system is also easy to use from tablets. It is suggested that waiters can use tablets to manage orders.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/EmNvZEz.jpg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.H3_SHORT}
        rusText={'К разделам предусмотрены подсказки в виде поп-апов с подробной информацией, появляющиеся при нажатии на кнопку с пиктограммой вопроса.'}
        engText={'Sections are provided with tooltips in the form of pop-ups with detailed information that appear when you click on the button with a question icon.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.FULL_WIDTH}>
        <ImageComponent withParallax={true} src={'https://i.imgur.com/wzHll17.jpg'} scale={1.15}/>
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'После релиза веб-версии будет разработано кроссплатформенное мобильное приложение, о котором дополнительно будет рассказано позже на странице проекта.'}
        engText={'After the release of the web version, a cross-platform mobile application will be developed, which will be further described later.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/IdtZXKi.jpg'} />
      </MediaComponentWrapper>
      <Credits lines={[
        { rusName: 'Lead Product and UI/UX design:', rusVal: 'Глеб Коссов', engName: 'Product and UI/UX design:', engVal: 'Gleb Kossov' },
        { engName: 'Licensed mockups:', engVal: 'LS graphics', link: 'https://www.ls.graphics/' }
      ]}/>
    </>
  )
}

export default YourmealPage