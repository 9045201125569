import React from "react";
import useHoverAndClick from "../../../../utils/hooks/UseHoverAndClickHook";
import styled from "styled-components";
import {ExternalLinks} from "../../../../constants/ExternalLinks";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../../redux/ReduxStore";
import {Lang} from "../../../../constants/Lang";
import {ColorTheme} from "../../../../constants/ColorTheme";

const StyledCopyrightLink = styled.a<{
  $colorTheme: ColorTheme;
  $hovered: boolean,
  $clicked: boolean
}>`
  position: relative;
  display: flex;
  color: ${props => (props.$colorTheme === ColorTheme.DARK 
                  ? props.$hovered || props.$hovered ? 'var(--elements-gray-3-dark, #5D5D5DFF)' : 'var(--text-gray-main-dark, #ACACACFF)'
                  : props.$hovered || props.$hovered ? 'var(--elements-gray-3, #D7D7D7FF)' : 'var(--text-gray-main, #5F626C)')
  };
  text-decoration: none;
  ${props => (props?.$hovered || props?.$clicked) && 'cursor: pointer'};
`

const StyledUnderline = styled.div<{
  $isShown: boolean;
  $colorTheme: ColorTheme;
}>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.5px;
  opacity: ${props => props.$isShown ? 1 : 0};
  background-color: ${props => (props.$colorTheme === ColorTheme.DARK
          ? 'var(--elements-gray-3-dark, #5D5D5DFF)'
          : 'var(--elements-gray-3, #D7D7D7FF)'
  )};
  transition: 0.2s all ease-in-out;
`

const CopyrightLink: React.FC = () => {
  const {isHovered, isClicked, ...eventHandlers} = useHoverAndClick()
  const currLang = useSelector((state: RootStoreState) => state.lang)
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)

  return(
    <StyledCopyrightLink
      $hovered={isHovered}
      $clicked={isClicked}
      $colorTheme={currTheme}

      href={ExternalLinks.DEVELOPER}
      target={'_blank'}
      className={`animation-02s-all ${currTheme === ColorTheme.DARK ? 'text-gray-main-text-dark' : 'text-gray-main-text'}`}
      {...eventHandlers}
    >
      {currLang === Lang.RUS ? 'Разработал Сергей Заозерских' : 'Developed by Sergey Zaozerskikh'}
      <StyledUnderline $isShown={isHovered || isClicked} $colorTheme={currTheme}/>
    </StyledCopyrightLink>
  )
}

export default CopyrightLink